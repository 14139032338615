import React from 'react';

import PropTypes from 'prop-types';

import {
    Grid,
    Typography,
    Button,
    Link,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import {
    Tv,
    CallMissed,
    InfoOutlined,
} from '@material-ui/icons';

import setup from '../../../assets/setup/Setup.exe';
import ContentHeader from '../ContentHeader';
import LightTooltip from '../../LightTooltip';

const Installation = (props) => {
    const { onNext } = props;

    const [checked, setChecked] = React.useState(false);

    const handleCheck = () => setChecked(!checked);

    const handleNext = () => {
        if (onNext) {
            onNext();
        }
    };

    return (
        <Grid container spacing={8}>
            <Grid item xs={12}>
                <ContentHeader libelle='ACTIVATION DE VOTRE ESPACE CLIENT' />
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} style={{ paddingTop: '2rem' }}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>
                                    Installez l’outil « Hôte de connexion » sur cet ordinateur si
                                    vous souhaitez en prendre le contrôle à distance ultérieurement
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <Link
                                        href={setup}
                                        download
                                    >
                                        Télécharger l’outil « Hôte de connexion » sur cet ordinateur
                                    </Link>
                                </Typography>
                            </Grid>
                            <ul>
                                <Typography paragraph>
                                    <li>
                                        Une fois le téléchargement effectué, exécutez l’outil
                                        « Hôte de connexion » sur votre ordinateur

                                    </li>
                                </Typography>
                                <Typography paragraph>
                                    <li>
                                        Durant le processus d’installation, vous devrez saisir
                                        votre adresse email et le mot de passe que vous venez de choisir
                                    </li>
                                </Typography>
                                <Typography paragraph>
                                    <li>
                                        Une fois l’installation terminée, vous pourrez accéder à
                                        distance à ce poste
                                        <LightTooltip
                                            placement='bottom'
                                            title='Le poste qui sera contrôlé à distance par le poste client'
                                        >
                                            <span style={{ color: '#1976d2' }}>
                                                {' '}
                                                « Hôte »
                                                <InfoOutlined style={{ marginBottom: 5, fontSize: '1rem' }} />
                                                {' '}
                                            </span>
                                        </LightTooltip>
                                        depuis un autre ordinateur
                                        (le poste client) depuis le menu « Mes postes »
                                    </li>
                                </Typography>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={checked}
                                    onChange={handleCheck}
                                    color="primary"
                                />
                            )}
                            label="J’ai téléchargé et installé l’outil « Hôte de connexion »"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={5}
                        >
                            <Grid item>
                                {!checked
                                    ? (
                                        <Button
                                            disableRipple
                                            onClick={handleNext}
                                            variant="outlined"
                                            color='primary'
                                            startIcon={(<CallMissed style={{ transform: 'rotate(180deg)' }} />)}
                                        >
                                            Je ne souhaite pas configurer mon 1er poste
                                            « Hôte », aller au menu « Mes Postes »
                                        </Button>
                                    )
                                    : (
                                        <Button
                                            disableRipple
                                            onClick={handleNext}
                                            variant="contained"
                                            color='primary'
                                            startIcon={<Tv />}
                                        >
                                            Aller au menu « Mes Postes »

                                        </Button>
                                    )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

Installation.propTypes = {
    onNext: PropTypes.func,
};

export default Installation;
