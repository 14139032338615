import React from 'react';

import {
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
} from '@material-ui/core';
import {
    ExpandMore,
    ArrowRightAlt,
} from '@material-ui/icons';

const ControlMac = (props) => {
    return (
        <ExpansionPanel {...props}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant='h6'>... Prendre le contrôle de mon poste au bureau depuis un Mac</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <ol>
                    <li>
                        <Typography>Ouvrir l'App Store Mac</Typography>
                    </li>
                    <li>
                        <Typography>Installer l'application Microsoft Remote Desktop</Typography>
                    </li>
                    <li>
                        <Typography>Ouvrir l'application Microsoft Remote Desktop</Typography>
                    </li>
                    <li>
                        <Typography style={{ verticalAlign: 'middle', display: 'flex' }}>
                            Dans la barre de menu, sélectionnez Fichier
                            <ArrowRightAlt />
                            {' '}
                            Importer
                        </Typography>
                    </li>
                    <li>
                        <Typography>Sélectionner le fichier .rdp téléchargé sur monposteadistance.fr</Typography>
                    </li>
                    <li>
                        <Typography>Cliquer sur Ouvrir</Typography>
                    </li>
                    <li>
                        <Typography style={{ verticalAlign: 'middle', display: 'flex' }}>
                            Sur le fichier .rdp importé, faire clic droit
                            <ArrowRightAlt />
                            {' '}
                            préférence
                        </Typography>
                    </li>
                    <li>
                        <Typography>Dans le champ utilisateur, retirer la notion de domaine pour qu'il ne reste que le nom d'utilisateur</Typography>
                    </li>
                </ol>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default ControlMac;