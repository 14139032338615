import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    DialogActions,
    Button,
    TextField,
    InputAdornment,
    IconButton,
    ListItemIcon,
    CircularProgress,
} from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';

import { cimUsers } from '../../../api/routes';
import { LogContext } from '../../Context';

const UserList = (props) => {
    const {
        onClose,
        onSelect,
        selected,
        identifiantCim,
    } = props;

    const { token, isAuthenticationValid } = React.useContext(LogContext);

    const [users, setUsers] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState('');

    React.useEffect(() => {
        setLoading(true);
        fetch(cimUsers(identifiantCim), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((data) => {
                        setLoading(false);
                        setUsers(_.sortBy(data, [
                            'last_name',
                            'first_name',
                            'email',
                        ]));
                    });
                } else {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    }, [identifiantCim, isAuthenticationValid, token]);

    const formatUser = (user) => `${_.toUpper(user.last_name)} ${user.first_name} / ${user.email}`;

    const handleClose = () => {
        if (onClose) onClose();
    };

    const handleSelect = (user) => () => {
        if (onSelect) onSelect(user);
    };

    const handleChange = (event) => {
        const { target } = event || {};
        const { value } = target || {};

        setSearchValue(value);
    };

    const handleSearchValueClear = () => setSearchValue('');

    const filteredUsers = _.filter(
        users,
        (user) => _.isEmpty(searchValue)
        || _.includes(_.toLower(user.first_name), _.toLower(searchValue))
        || _.includes(_.toLower(user.last_name), _.toLower(searchValue))
        || _.includes(_.toLower(user.email), _.toLower(searchValue)),
    );

    if (loading) {
        return (
            <Dialog onClose={handleClose} open maxWidth='lg'>
                <DialogTitle onClose={handleClose} style={{ textAlign: 'center' }}>
                    Inviter un utilisateur existant
                </DialogTitle>
                <DialogContent
                    dividers
                    style={{
                        width: 500,
                        minHeight: 250,
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={24} />
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        color="primary"
                        disableRipple
                        startIcon={<Close />}
                    >
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Dialog onClose={handleClose} open maxWidth='lg'>
            <DialogTitle onClose={handleClose} style={{ textAlign: 'center' }}>
                Inviter un utilisateur existant
            </DialogTitle>
            <DialogContent
                dividers
                style={{
                    width: 500,
                    minHeight: 250,
                    overflow: 'hidden',
                }}
            >
                {_.isEmpty(users)
                    ? 'Aucun utilisateur existant'
                    : (
                        <>
                            <TextField
                                size='small'
                                fullWidth
                                variant="outlined"
                                placeholder='Rechercher un utilisateur'
                                value={searchValue}
                                onChange={handleChange}
                                {...!_.isEmpty(searchValue) && {
                                    InputProps: {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton disableRipple
                                                    size='small'
                                                    onClick={handleSearchValueClear}
                                                >
                                                    <Close />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                                style={{
                                    marginBottom: 10,
                                }}
                            />
                            {_.isEmpty(filteredUsers)
                                ? 'Aucun utilisateur correspondant'
                                : (
                                    <List
                                        disablePadding
                                        style={{
                                            overflow: 'auto',
                                            maxHeight: '100%',
                                            height: 250,
                                        }}
                                    >
                                        {_.map(filteredUsers, (user) => {
                                            const { email, id, username } = user;

                                            const libelle = formatUser(user);
                                            const isSelected = _.find(selected, (sel) => sel === email || sel === username);

                                            return (
                                                <ListItem
                                                    key={id}
                                                    button
                                                    onClick={handleSelect(user)}
                                                    disableRipple
                                                    style={{ whiteSpace: 'pre' }}
                                                    disabled={isSelected}
                                                >
                                                    {!_.isEmpty(selected) && (
                                                        <ListItemIcon style={{ minWidth: 30 }}>
                                                            {isSelected && <Check size='small' />}
                                                        </ListItemIcon>
                                                    )}
                                                    {
                                                        !_.isEmpty(searchValue)
                                                            ? _.map(_.split(libelle, searchValue), (splitted, index) => {
                                                                if (index !== 0) {
                                                                    return (
                                                                        <>
                                                                            <b>{searchValue}</b>
                                                                            {splitted}
                                                                        </>
                                                                    );
                                                                }

                                                                return splitted;
                                                            })
                                                            : libelle
                                                    }
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                )}
                        </>
                    )}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'flex-end' }}>
                <Button
                    variant="outlined"
                    onClick={handleClose}
                    color="primary"
                    disableRipple
                    startIcon={<Close />}
                >
                    Annuler
                </Button>
            </DialogActions>
        </Dialog>
    );
};

UserList.propTypes = {
    onClose: PropTypes.func,
    onSelect: PropTypes.func,
    selected: PropTypes.array,
    identifiantCim: PropTypes.number,
};

export default UserList;
