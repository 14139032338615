import _ from 'lodash';
import React from 'react';

import {
    Grid,
    Hidden,
} from '@material-ui/core';

import LogContext from '../Context/LogContext';

import ApplicationMenu from './ApplicationMenu';
import ApplicationContent from './ApplicationContent';
import Authentification from '../Authentification/Authentification';
import { customerInformations, userInformations } from '../../api';

const Application = () => {
    const [token, setToken] = React.useState('');
    const [activeMenu, setActiveMenu] = React.useState(0);
    const [firstConnexion, setFirstConnexion] = React.useState(false);
    const [collectivites, setCollectivites] = React.useState(null);
    const [activeCollectivite, setActiveCollectivite] = React.useState();
    const [activeUser, setActiveUser] = React.useState();
    const [login, setLogin] = React.useState('');
    const [loading, setLoading] = React.useState([]);
    const [admin, setAdmin] = React.useState(false);

    const {
        name: activeCollectiviteName,
    } = activeCollectivite || {};

    const disconnect = (manuallyDisconnected = true) => {
        setActiveUser();
        setActiveCollectivite();
        setActiveMenu(0);
        setToken('');
        setFirstConnexion(false);
        setCollectivites(null);
        setLogin('');

        localStorage.removeItem('context');
        sessionStorage.setItem(
            'auth',
            JSON.stringify({
                manuallyDisconnected,
            }),
        );

        return false;
    };

    const validAuthentication = React.useCallback((apiResponse) => {
        if (apiResponse.status && apiResponse.status === 498) {
            disconnect(false);
        }

        return true;
    }, []);

    React.useEffect(() => {
        if (token && !admin) {
            setLoading((prevLoading) => ([...prevLoading, 'customerInformations']));

            fetch(customerInformations(),
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                }).then((response) => {
                if (validAuthentication(response)) {
                    if (response.status && response.status === 200) {
                        response.json().then((data) => {
                            setActiveCollectivite(data);

                            setLoading((prevLoading) => ([..._.filter(prevLoading, (load) => load !== 'customerInformations')]));
                        });
                    }
                }
            });
        }
    }, [
        validAuthentication,
        token,
        admin,
    ]);

    const getActiveUser = React.useCallback(() => {
        if (!admin) {
            fetch(userInformations(), {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                if (validAuthentication(response)) {
                    if (response.status && response.status === 200) {
                        response.json().then((data) => {
                            setActiveUser(data);

                        });
                    }
                }
            });
        }
    }, [
        admin,
        token,
        validAuthentication,
    ]);

    React.useEffect(() => {
        if (token) {
            getActiveUser();
        }
    }, [
        getActiveUser,
        token,
        validAuthentication,
    ]);

    React.useEffect(() => {
        const context = JSON.parse(localStorage.getItem('context'));

        const {
            token: tokenContext,
            collectivites: collectivitesContext,
            login: loginContext,
            admin: adm,
        } = context || {};

        setCollectivites(collectivitesContext);
        setLogin(loginContext);
        setAdmin(adm);
        setToken(tokenContext);
    }, []);

    const initializeLocalStorage = (itemsCollectivites, valueLogin, tok, adm) => {
        const serializedContext = JSON.stringify({
            token: tok,
            collectivites: itemsCollectivites,
            login: valueLogin,
            admin: adm,
        });

        localStorage.setItem('context', serializedContext);
        sessionStorage.removeItem('auth');
    };

    const handleMenuChange = (menu) => setActiveMenu(menu);

    const handleAuth = (valueLogin, itemsCollectivites = null, first = false, tok = null, adm = false) => {
        setLogin(valueLogin);
        if (!_.isNil(itemsCollectivites)) {
            setCollectivites(itemsCollectivites);
        }
        setFirstConnexion(first);
        setAdmin(adm);

        initializeLocalStorage(itemsCollectivites, valueLogin, tok, adm);
    };

    const handleActivationEnd = () => setFirstConnexion(false);

    return (
        <LogContext.Provider value={
            {
                token,
                setToken,
                isAuthenticationValid: validAuthentication,
                disconnect,
                activeUser,
                refreshActiveUser: getActiveUser,
            }
        }
        >
            {(!_.isNil(token) && !_.isEmpty(token))
                ? (
                    <Grid container style={{ height: '100%' }}>
                        <Hidden only={['xs', 'sm']}>
                            <Grid
                                item
                                style={{ width: 250 }}
                            >
                                <ApplicationMenu
                                    onChange={handleMenuChange}
                                    firstConnexion={firstConnexion}
                                    activeMenu={activeMenu}
                                    collectivites={collectivites}
                                    login={login}
                                    onAuth={handleAuth}
                                    onDisconnect={disconnect}
                                    activeCollectiviteName={activeCollectiviteName}
                                    adminCim={admin}
                                    {...!_.isEmpty(loading) && { loading: true }}
                                />
                            </Grid>
                            <Grid
                                item
                                style={{
                                    width: 'calc(100% - 250px)',
                                    height: '100%',
                                    overflowY: 'auto'
                                }}
                            >
                                <ApplicationContent
                                    firstConnexion={firstConnexion}
                                    activeMenu={activeMenu}
                                    onActivationEnd={handleActivationEnd}
                                    activeCollectivite={activeCollectivite}
                                    admin={admin}
                                    {...!_.isEmpty(loading) && { loading: true }}
                                />
                            </Grid>
                        </Hidden>
                        <Hidden only={[
                            'md',
                            'lg',
                            'xl',
                        ]}
                        >
                            <Grid
                                item
                                style={{ width: 100 }}
                            >
                                <ApplicationMenu
                                    onChange={handleMenuChange}
                                    activeMenu={activeMenu}
                                    firstConnexion={firstConnexion}
                                    collectivites={collectivites}
                                    login={login}
                                    compact
                                    onAuth={handleAuth}
                                    onDisconnect={disconnect}
                                    activeCollectiviteName={activeCollectiviteName}
                                    adminCim={admin}
                                    {...!_.isEmpty(loading) && { loading: true }}
                                />
                            </Grid>
                            <Grid
                                item
                                style={{
                                    width: 'calc(100% - 100px)',
                                    height: '100%',
                                    overflowY: 'auto'
                                }}
                            >
                                <ApplicationContent
                                    firstConnexion={firstConnexion}
                                    activeMenu={activeMenu}
                                    onActivationEnd={handleActivationEnd}
                                    activeCollectivite={activeCollectivite}
                                    admin={admin}
                                    {...!_.isEmpty(loading) && { loading: true }}
                                />
                            </Grid>
                        </Hidden>
                    </Grid>
                )
                : (
                    <Authentification
                        onAuth={handleAuth}
                        collectivites={collectivites}
                    />
                )}
        </LogContext.Provider>
    );
};

export default Application;
