import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
    Box,
    Typography,
    CircularProgress,
    Button,
} from '@material-ui/core';
import {
    TreeView,
    TreeItem,
} from '@material-ui/lab';
import {
    Add,
    Tv,
    Remove,
    DesktopAccessDisabled,
    StarBorderOutlined,
    DeleteOutlined,
    EditOutlined,
} from '@material-ui/icons';

import { DashboardContext, LogContext } from '../Context';
import { customerUsers, deleteCustomerUserDashboard } from '../../api';
import DialogInviteNewUser from '../Dialog/DialogInviteNewUser';
import DialogManageUser from '../Dialog/DialogManageUser';
import DialogConfirm from '../Dialog/DialogConfirm';
import LightTooltip from '../LightTooltip';

const DashboardUser = (props) => {
    const { onLoad } = props;

    const [loading, setLoading] = React.useState(false);
    const [hovered, setHovered] = React.useState();
    const [openModal, setOpenModal] = React.useState();
    const [openConfirm, setOpenConfirm] = React.useState();

    const { token, isAuthenticationValid } = React.useContext(LogContext);
    const { users, setUsers, activeCustomer, reload, setReload } = React.useContext(DashboardContext);

    const { id_customer: identifiantCustomer, id_cim: identifiantCim } = activeCustomer || {};

    const getUsers = React.useCallback(() => {
        setLoading(true);

        if (onLoad) onLoad(true);
        if (reload) setReload(false);

        fetch(customerUsers(identifiantCustomer), {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((data) => {
                        setUsers(data);
                        setLoading(false);
                        if (onLoad) onLoad(false);
                    });
                } else {
                    setLoading(false);
                    if (onLoad) onLoad(false);
                }
            }
        });
    }, [onLoad, reload, setReload, identifiantCustomer, token, isAuthenticationValid, setUsers])

    React.useEffect(() => {
        if (!_.isNil(identifiantCustomer)) {
            getUsers();
        }
    }, [getUsers, identifiantCustomer]);

    const handleSend = () => { getUsers(); }
    
    const handleMouseEnter = (identifiantUser) => () => { setHovered(identifiantUser) }

    const handleMouseLeave = () => { setHovered(null) }

    const handleOpenModal = (e, idUser) => { e.stopPropagation(); setOpenModal(idUser); }

    const handleCloseModal = () => setOpenModal(null);

    const handleOpenConfirm = (e, identifiantUser) => {
        e.stopPropagation();
        setOpenConfirm(identifiantUser);
    }

    const handleDeleteUser = (identifiantUser = null) => {
        setLoading(true);

        fetch(deleteCustomerUserDashboard(identifiantCustomer, identifiantUser ? identifiantUser : openConfirm), {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((data) => {
                        getUsers();
                        setOpenConfirm();
                        setOpenModal();
                        setLoading(false);
                    });
                }
            }
        });

    }

    const renderHeader = (
        <Box style={{ paddingBottom: '1em', display: 'flex', alignItems:'center' }}>
            <Typography
                variant='h6'
                style={{
                    borderLeft: '5px solid #0064AE',
                    paddingLeft: '0.5em',
                    flex: 1
                }}
            >
                Liste des utilisateurs
            </Typography> 
            <Typography
                style={{
                    fontSize: '1rem',
                    fontWeight: 400,
                    lineHight: 1.43,
                    color: 'rgba(0, 0, 0, 0.54)',
                    display: 'flex'
                }}
            >
                <StarBorderOutlined fontSize='small' style={{ paddingRight: '.25em' }} />
                Administrateur
            </Typography>
        </Box>
    )

    const userSelected = _.find(users, user => user.id_user === openModal);

    let label = '';

    if (!_.isNil(userSelected)) {
        label = `${_.toUpper(userSelected.last_name)} ${userSelected.first_name}`;
    }

    return (
        <Box style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {renderHeader}
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    overflowY: 'auto',
                    paddingBottom: '1em',
                    marginBottom: '0.5em',
                }}
            >
                {!_.isEmpty(users) 
                    ? 
                        (
                            <TreeView
                                style={{ flex: 1 }}
                                disableSelection
                                defaultCollapseIcon={<Remove />}
                                defaultExpandIcon={<Add />}
                                defaultEndIcon={<DesktopAccessDisabled title='Aucun poste rattaché' />}
                            >
                                {_.map(users, (user) => {
                                    const {
                                        first_name: firstName,
                                        last_name: lastName,
                                        id_user: identifiantUser,
                                        principal_name: principalName,
                                        computers,
                                        customer_users: customerUsers,
                                    } = user;

                                    const label = `${_.toUpper(lastName)} ${firstName}`;
                                    const { role } = customerUsers[0];

                                    return (
                                        <TreeItem
                                            nodeId={identifiantUser}
                                            label={(
                                                <Box
                                                    onFocus={handleMouseEnter(identifiantUser)}
                                                    onMouseEnter={handleMouseEnter(identifiantUser)}
                                                    onMouseLeave={handleMouseLeave}
                                                    style={{display:'flex', flexDirection:'inherit'}}
                                                >
                                                    <Box style={{ flex: 1 }}>
                                                        <Typography >
                                                            {label}
                                                            {role && <StarBorderOutlined color='primary' style={{ paddingLeft: '.5em' }} fontSize='small' />}
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                fontSize: '1rem',
                                                                fontWeight: 400,
                                                                lineHight: 1.43,
                                                                color: 'rgba(0, 0, 0, 0.54)',
                                                            }}
                                                        >
                                                            {principalName}
                                                        </Typography>
                                                    </Box>
                                                    <Box style={{display:'flex'}}>
                                                        <LightTooltip title="Gérer l'utilisateur">
                                                            <Button
                                                                disableRipple
                                                                style={{ visibility: hovered === identifiantUser ? 'visible' : 'hidden' }}
                                                                onClick={(e) => handleOpenModal(e, identifiantUser)}
                                                            >
                                                                <EditOutlined />
                                                            </Button>
                                                            
                                                        </LightTooltip>
                                                        <LightTooltip title="Supprimer l'utilisateur">
                                                            <Button
                                                                disableRipple
                                                                style={{ visibility: hovered === identifiantUser ? 'visible' : 'hidden' }}
                                                                onClick={(e) => handleOpenConfirm(e, identifiantUser)}
                                                                >
                                                                <DeleteOutlined />
                                                            </Button>
                                                        </LightTooltip>
                                                    </Box>
                                                </Box>
                                            )}
                                        >
                                            {_.map(computers, (computer) => {
                                                const {
                                                    id_computer_rg: identifiantRg,
                                                    computer_name: name,
                                                } = computer;

                                                return (
                                                    <TreeItem
                                                        nodeId={identifiantRg}
                                                        endIcon={<Tv />}
                                                        label={(
                                                            <>
                                                                <Typography>
                                                                    {identifiantRg}
                                                                </Typography>
                                                                <Typography
                                                                    style={{
                                                                        fontSize: '1rem',
                                                                        fontWeight: 400,
                                                                        lineHight: 1.43,
                                                                        color: 'rgba(0, 0, 0, 0.54)',
                                                                    }}
                                                                >
                                                                    {name}
                                                                </Typography>
                                                            </>
                                                        )}
                                                    />
                                                );
                                            })}
                                        </TreeItem>
                                    );
                                })}
                            </TreeView>
                        )
                    :
                        (
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                    overflowY: 'auto',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {_.isNil(users) ? <CircularProgress size={24} /> : <Typography>Aucun utilisateur</Typography>} 
                            </Box>
                        )
                }
            </Box>
                <Box style={{display: 'flex', alignItems:'center'}}>
                    <Box style={{flex: 1}}>
                        <DialogInviteNewUser
                            onSend={handleSend}
                            users={users}
                            identifiantCustomer={identifiantCustomer}
                            identifiantCim={identifiantCim}
                            smallButton
                        />
                    </Box>
                    {!_.isEmpty(users) && (
                        <Typography style={{
                            fontSize: '1rem',
                            fontWeight: 400,
                            lineHight: 1.43,
                            color: 'rgba(0, 0, 0, 0.54)',
                            float: 'right',
                        }}
                        >
                            {`${_.size(users)} utilisateurs`}
                        </Typography>
                    )}
            </Box>
            {!_.isNil(openModal) && (
                <DialogManageUser
                    libelleUser={label}
                    identifiantUser={openModal}
                    onSave={handleSend}
                    onUserDelete={handleDeleteUser}
                    identifiantCustomer={identifiantCustomer}
                    onClose={handleCloseModal}
                />
            )}
            {!_.isNil(openConfirm) && (
                <DialogConfirm
                    text='Êtes-vous sûr de vouloir supprimer cet utilisateur ?'
                    onConfirm={handleDeleteUser}
                    onCancel={() => setOpenConfirm(null)}
                    loading={loading}
                />
            )}
        </Box>
    );
};

DashboardUser.propTypes = {
    onLoad: PropTypes.func,
};

export default DashboardUser;
