import _ from 'lodash';
import React from 'react';

import {
    Grid,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Button,
    CircularProgress,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

import LogContext from '../Context/LogContext';
import ContentHeader from './ContentHeader';
import DialogNewComputer from '../Dialog/DialogNewComputer';
import DialogDownloadTool from '../Dialog/DialogDownloadTool';
import LightTooltip from '../LightTooltip';
import {
    userComputers,
    deleteUserComputer,
} from '../../api';
import DialogConfirm from '../Dialog/DialogConfirm';

const Computers = () => {
    const { token, isAuthenticationValid } = React.useContext(LogContext);

    const [selectedRow, setSelectedRow] = React.useState(null);
    const [computers, setComputers] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);

    const getUserComputers = React.useCallback(() => {
        setLoading(true);

        fetch(userComputers(), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((data) => {
                        setComputers(data);

                        if (!_.isEmpty(data)) {
                            setSelectedRow(0);
                        }
                    });
                }
            }

            setLoading(false);
        });
    }, [isAuthenticationValid, token]);

    React.useEffect(() => {
        getUserComputers();
    }, [getUserComputers]);

    const handleClickRow = (index) => setSelectedRow(index);

    const handleDialogCancel = () => setOpenConfirm();

    const handleDetached = () => {
        setDeleteLoading(true);

        fetch(deleteUserComputer(computers[selectedRow].id_computer), {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    setComputers(
                        _.filter(
                            computers,
                            (c) => c.id_computer !== computers[selectedRow].id_computer,
                        ),
                    );
                    setSelectedRow(null);
                }
            }
        }).finally(() => {
            setDeleteLoading(false);
            setOpenConfirm(false)}
        );
    };

    const handleSubmit = () => {
        getUserComputers();
    };

    const handleCloseDialog = () => { getUserComputers(); };

    const renderContentWithoutComputers = () => (
        <>
            <Typography gutterBottom paragraph>
                <i>
                    Aucun poste
                    <LightTooltip
                        placement='bottom'
                        title='Le poste qui sera contrôlé à distance par le poste client'
                    >
                        <span style={{ color: '#1976d2' }}>
                            {' '}
                            « Hôte »
                            <InfoOutlined style={{ marginBottom: 5, fontSize: '1rem' }} />
                            {' '}
                        </span>
                    </LightTooltip>
                    n’est encore rattaché à votre compte :
                </i>
            </Typography>
            <ul>
                <Typography gutterBottom>
                    <li>
                        <i>
                            Soit le processus d’installation de l’outil « Hôte de connexion »
                            sur votre ordinateur de bureau n’est pas encore terminé, patientez jusqu’à
                            la fin de l’installation puis actualisez cette page.
                        </i>
                    </li>
                </Typography>
                <Typography gutterBottom>
                    <li>
                        <i>
                            Soit vous n’avez pas encore installé l’outil « Hôte de connexion »
                            sur votre ordinateur de bureau,
                            ou si l’ordinateur
                            <LightTooltip
                                placement='bottom'
                                title='Le poste qui sera contrôlé à distance par le poste client'
                            >
                                <span style={{ color: '#1976d2' }}>
                                    {' '}
                                    « Hôte »
                                    <InfoOutlined style={{ marginBottom: 5, fontSize: '1rem' }} />
                                    {' '}
                                </span>
                            </LightTooltip>
                            dont vous souhaitez prendre le contrôle n’apparait pas ici,
                            cliquez sur le bouton « Ajouter un poste ».
                        </i>
                    </li>
                </Typography>
            </ul>
        </>
    );

    const renderContentWithComputers = () => (
        <Table size="small">
            <TableBody>
                {_.map(computers, (computer, index) => {
                    console.log('computer: ', computer);
                    const {
                        computer_name: computerName,
                        id_computer: identifiant,
                        id_computer_rg: identifiantRg,
                    } = computer;

                    return (
                        <TableRow
                            key={`RowComputer${identifiant}`}
                            onClick={() => handleClickRow(index)}
                            selected={selectedRow === index}
                        >
                            <TableCell>
                                <Typography>{computerName}</Typography>
                            </TableCell>
                            <TableCell>
                                <DialogDownloadTool
                                    libellePoste={computerName}
                                    buttonVisible={!_.isNil(selectedRow) && selectedRow === index}
                                    identifiantRg={identifiantRg}
                                />
                            </TableCell>
                            <TableCell>
                                <Button
                                    disableRipple
                                    color='secondary'
                                    style={{ visibility: !_.isNil(selectedRow) && selectedRow === index ? 'visible' : 'hidden' }}
                                    onClick={() => setOpenConfirm(true)}
                                >
                                    Supprimer ce poste
                                </Button>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );

    if (loading) {
        return (
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <ContentHeader libelle='PRENDRE LE CONTRÔLE A DISTANCE DE MES POSTES' />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} style={{ paddingTop: '2rem' }}>
                        <Grid item xs={12}>
                            <Typography variant='h6'>
                                Postes
                                <LightTooltip
                                    placement='bottom'
                                    title='Le poste qui sera contrôlé à distance par le poste client'
                                >
                                    <span style={{ color: '#1976d2' }}>
                                        {' '}
                                        « Hôte »
                                        <InfoOutlined style={{ marginBottom: 5, fontSize: '1rem' }} />
                                        {' '}
                                    </span>
                                </LightTooltip>
                                rattachés à votre compte
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <CircularProgress size={24} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container spacing={8}>
            <Grid item xs={12}>
                <ContentHeader libelle='PRENDRE LE CONTRÔLE A DISTANCE DE MES POSTES' />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3} style={{ paddingTop: '2rem' }}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            Postes
                            <LightTooltip
                                placement='bottom'
                                title='Le poste qui sera contrôlé à distance par le poste client'
                            >
                                <span style={{ color: '#1976d2' }}>
                                    {' '}
                                    « Hôte »
                                    <InfoOutlined style={{ marginBottom: 5, fontSize: '1rem' }} />
                                    {' '}
                                </span>
                            </LightTooltip>
                            rattachés à votre compte
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        { _.isEmpty(computers)
                            ? renderContentWithoutComputers()
                            : renderContentWithComputers()}
                        {openConfirm && (
                            <DialogConfirm
                                text={
                                    <>
                                        Êtes-vous sur de vouloir détacher ce poste de votre compte ?
                                        <br />
                                        Il sera toujours possible de le rattacher par la suite.
                                    </>
                                }
                                onConfirm={handleDetached}
                                onCancel={handleDialogCancel}
                                loading={deleteLoading}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <DialogNewComputer
                            onSubmit={handleSubmit}
                            attachedComputers={computers}
                            onClose={handleCloseDialog}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Computers;
