import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
    Button,
    Grid,
    CircularProgress,
} from '@material-ui/core';
import {
    Check, Close,
} from '@material-ui/icons';

import LogContext from '../Context/LogContext';
import TransferList from '../TransferList/TransferList';
import {
    customerUsers,
    createUserComputer,
    deleteUserComputer,
    createCustomerUserComputer,
    deleteCustomerUserComputer,
    createCustomerComputer,
} from '../../api';

const DialogManageComputer = (props) => {
    const {
        computer,
        usersAttached: usersAttachedProps,
        onSave,
        identifiantCustomer,
        onClose,
    } = props;
    
    const { token, isAuthenticationValid } = React.useContext(LogContext);

    const {
        computer_name: name,
        id_computer_rg: identifiantRg,
        id_computer: identifiant,
    } = computer;

    const formatteUsers = users => _.map(users, user => ({
        libelle: `${user.first_name} ${_.toUpper(user.last_name)}`,
        ...user,
    }));

    const [
        loading,
        setLoading,
    ] = React.useState(false);

    const [
        usersAvailable,
        setUsersAvailable,
    ] = React.useState([]);

    const [
        usersAttached,
        setUsersAttached,
    ] = React.useState(formatteUsers(usersAttachedProps));

    React.useEffect(() => {
        setUsersAttached(formatteUsers(usersAttachedProps));
    }, [usersAttachedProps]);

    React.useEffect(() => {
        fetch(customerUsers(identifiantCustomer), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((responseAvailable) => {
            if (isAuthenticationValid(responseAvailable)) {
                if (responseAvailable.status && responseAvailable.status === 200) {
                    responseAvailable.json().then((dataAvailable) => {
                        setUsersAvailable(
                            _.filter(
                                formatteUsers(dataAvailable),
                                user => (!_.find(formatteUsers(usersAttachedProps), ua => ua.id_user === user.id_user)),
                            ),
                        );
                    });
                }
            }
        });
    }, [usersAttachedProps, identifiantRg, token, isAuthenticationValid, identifiantCustomer]);

    const handleClose = () => { if(onClose) onClose(); }

    const handleTransferListChange = (array, moveTo) => {
        if (moveTo === 'left') {
            setUsersAvailable([
                ...usersAvailable,
                ...array,
            ]);

            const usersAttachedDeleted = _.filter(usersAttached, user => !_.includes(array, user));
            setUsersAttached(usersAttachedDeleted);
        } else {
            setUsersAttached([
                ...usersAttached,
                ...array,
            ]);

            const usersAvailableDeleted = _.filter(usersAvailable, user => !_.includes(array, user));
            setUsersAvailable(usersAvailableDeleted);
        }
    };

    const handleSave = () => {
        setLoading(true);

        const newAttached = _.filter(
            usersAttached,
            user => !_.find(usersAttachedProps, ua => ua.id_user === user.id_user),
        );

        const removed = _.filter(
            usersAttachedProps,
            user => !_.find(usersAttached, ua => ua.id_user === user.id_user),
        );
        const pendingPromises = [];

        if(_.isNil(identifiant) && _.size(newAttached) > 0){
            pendingPromises.push(
                fetch(createCustomerComputer(identifiantCustomer), {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        id_user: newAttached[0].id_user,
                        id_computer_rg: identifiantRg,
                        computer_name: name,
                    }),
                }).then((res) => {
                    if (isAuthenticationValid(res)) {
                        if (res.status && res.status === 200) {
                            res.json().then((data) => {
                                _.map(_.tail(newAttached), (user) => {
                                    pendingPromises.push(
                                        fetch(createCustomerUserComputer(identifiantCustomer), {
                                            method: 'POST',
                                            mode: 'cors',
                                            headers: {
                                                Accept: 'application/json',
                                                'Content-Type': 'application/json',
                                                Authorization: `Bearer ${token}`,
                                            },
                                            body: JSON.stringify({
                                                id_user: user.id_user,
                                                id_computer: data.id_computer,
                                            }),
                                        }).then(isAuthenticationValid)
                                    );
                                });
                            });
                        }
                    }
                })
            )
        } else {

            const routeCreate = identifiantCustomer 
                ? createCustomerUserComputer(identifiantCustomer) 
                : createUserComputer();

            _.map(newAttached, (user) => {
                pendingPromises.push(
                    fetch(routeCreate, {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            id_user: user.id_user,
                            id_computer: identifiant,
                        }),
                    }).then(isAuthenticationValid)
                );
            });
        }


        _.map(removed, (user) => {
            const routeDelete = identifiantCustomer 
                ? deleteCustomerUserComputer(identifiantCustomer,  user.id_user, identifiant)
                : deleteUserComputer(identifiant, user.id_user);

            pendingPromises.push(
                fetch(routeDelete, {
                    method: 'DELETE',
                    mode: 'cors',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }).then(isAuthenticationValid)
            );
        });

        Promise.all(pendingPromises).then(() => {
            handleClose();
            setLoading(false);

            if (onSave) onSave();
        });
    };
    
    return (
        <>
            <Dialog onClose={handleClose} open maxWidth='md'>
                <DialogTitle>
                    Gérer
                    {' '}
                    {name}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12}>
                            <Typography>
                                Sélectionnez les utilisateurs qui verront ce poste dans le menu « Mes postes »
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TransferList
                                left={usersAvailable}
                                right={usersAttached}
                                leftTitle='Utilisateurs disponibles'
                                rightTitle='Utilisateurs rattachés'
                                onChange={handleTransferListChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                    <Button
                        disableRipple
                        variant="outlined"
                        onClick={handleClose}
                        color="primary"
                        startIcon={<Close />}
                    >
                        Annuler
                    </Button>
                    <Button
                        disableRipple
                        variant="contained"
                        onClick={handleSave}
                        color="primary"
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={20} color='white' /> : <Check />}
                    >
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

DialogManageComputer.propTypes = {
    usersAttached: PropTypes.array,
    computer: PropTypes.object,
    onSave: PropTypes.func,
    identifiantCustomer: PropTypes.number,
    onClose: PropTypes.func,
};

DialogManageComputer.defaultProps = {
    identifiantCustomer: null
}

export default DialogManageComputer;
