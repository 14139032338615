import _ from 'lodash';
import React from 'react';

import {
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    Link,
} from '@material-ui/core';
import {
    ExpandMore,
    ArrowRightAlt,
} from '@material-ui/icons';

import multiEcran from '../../../assets/images/multiEcran.png';
import saveMultiEcran from '../../../assets/images/saveMultiScreen.png';

const MultiScreen = (props) => {
    const [illustrationVisible, setIllustrationVisible] = React.useState([]);

    const handleIllustrationVisible = (index) => {
        if (_.includes(illustrationVisible, index)) {
            const tmp = _.filter(illustrationVisible, index);
            setIllustrationVisible(tmp);
        } else {
            const tmp = _.union(illustrationVisible, [index]);
            setIllustrationVisible(tmp);
        }
    };

    return (
        <ExpansionPanel {...props}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant='h6'>... Afficher tous mes écrans</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <ol>
                    <li>
                        <Typography>Sélectionner le fichier &quot;.rdp&quot;</Typography>
                    </li>
                    <li>
                        <Typography style={{ verticalAlign: 'middle', display: 'flex' }}>
                            Faire clic droit
                            <ArrowRightAlt />
                            {' '}
                            modifier
                        </Typography>
                    </li>
                    <li>
                        <Typography>Aller dans l&apos;onglet &quot;Affichage&quot;</Typography>
                    </li>
                    <li>
                        <Typography>
                            Cocher la case &quot;Utiliser tous les moniteurs pour la session à distance&quot;
                            (
                            <Link style={{ cursor: 'pointer' }} onClick={() => handleIllustrationVisible(1)}>
                                {_.includes(illustrationVisible, 1)
                                    ? "Masquer l'illustration"
                                    : "Afficher l'illustration"}
                            </Link>
                            )
                        </Typography>
                        {_.includes(illustrationVisible, 1) && <img src={multiEcran} alt='multi-ecran' />}
                    </li>
                    <li>
                        <Typography>
                            Pour mémoriser cette option, retourner dans l&apos;onglet &quot;Général&quot; et cliquer sur &quot;Enregistrer&quot;
                            (
                            <Link style={{ cursor: 'pointer' }} onClick={() => handleIllustrationVisible(2)}>
                                {_.includes(illustrationVisible, 2)
                                    ? "Masquer l'illustration"
                                    : "Afficher l'illustration"}
                            </Link>
                            )
                        </Typography>
                        {_.includes(illustrationVisible, 2) && <img src={saveMultiEcran} alt='save-multi-ecran' />}
                    </li>
                    <li>
                        <Typography paragraph>Cliquer sur connexion.</Typography>
                    </li>
                </ol>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default MultiScreen;
