import React from 'react';

import PropTypes from 'prop-types';

import {
    Grid,
    Typography,
    Tabs,
    Tab,
    CircularProgress,
} from '@material-ui/core';

import { InfoOutlined } from '@material-ui/icons';
import ContentHeader from '../ContentHeader';
import ComputersTab from './ComputersTab';
import OrganisationTab from './OrganisationTab';
import UtilisateursTab from './UtilisateursTab';

import { LogContext } from '../../Context';
import { counter } from '../../../api';
import LightTooltip from '../../LightTooltip';

const Administration = (props) => {
    const {
        collectivite,
    } = props;

    const [activeTab, setActiveTab] = React.useState(0);
    const [compteur, setCompteur] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const {
        token,
        isAuthenticationValid,
        // activeUser,
    } = React.useContext(LogContext);

    const getCompteurs = React.useCallback(() => {
        setLoading(true);

        fetch(
            counter(),
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },

            },
        ).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((data) => {
                        setCompteur(data);
                    });
                }
            }

            setLoading(false);
        });
    }, [isAuthenticationValid, token]);

    React.useEffect(() => {
        getCompteurs();
    }, [getCompteurs]);

    const handleChangeTab = (e, indice) => { setLoading(true); setActiveTab(indice); setLoading(false); };

    const handleComputerDelete = () => { getCompteurs(); };

    const renderOrganisationTab = () => (
        <OrganisationTab
            compteur={compteur}
            collectivite={collectivite}
        />
    );

    // const renderOrganisationTabAction = () => (
    //     <DialogIncreaseNumberComputer
    //         utilisateur={activeUser}
    //         collectivite={collectivite}
    //     />
    // );

    const renderComputersTab = () => (<ComputersTab onComputerDelete={handleComputerDelete} />);

    const renderUsersTab = () => (<UtilisateursTab />);

    if (loading) {
        return (
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <ContentHeader libelle='ADMINISTRATION' />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} style={{ paddingTop: '1.5rem' }}>
                        <Grid item xs={12}>
                            <Tabs
                                value={activeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleChangeTab}
                            >
                                <Tab label={<Typography>Organisation</Typography>} wrapped />
                                <Tab
                                    label={(
                                        <Typography>
                                            Postes
                                            <LightTooltip
                                                placement='bottom'
                                                title='Le poste qui sera contrôlé à distance par le poste client'
                                            >
                                                <span style={{ color: '#1976d2' }}>
                                                    {' '}
                                                    « Hôte »
                                                    <InfoOutlined style={{ marginBottom: 2, fontSize: '1rem' }} />
                                                </span>
                                            </LightTooltip>
                                        </Typography>
                                )}
                                    wrapped
                                />
                                <Tab label={<Typography>Utilisateurs</Typography>} wrapped />
                            </Tabs>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 14, textAlign: 'center' }}>
                            <CircularProgress size={24} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container spacing={8}>
            <Grid item xs={12}>
                <ContentHeader libelle='ADMINISTRATION' />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} style={{ paddingTop: '1.5rem' }}>
                    <Grid item xs={12}>
                        <Tabs
                            value={activeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChangeTab}
                        >
                            <Tab label={<Typography>Organisation</Typography>} wrapped />
                            <Tab
                                label={(
                                    <Typography>
                                        Postes
                                        <LightTooltip
                                            placement='bottom'
                                            title='Le poste qui sera contrôlé à distance par le poste client'
                                        >
                                            <span style={{ color: '#1976d2' }}>
                                                {' '}
                                                « Hôte »
                                                <InfoOutlined style={{ marginBottom: 2, fontSize: '1rem' }} />
                                                {' '}
                                            </span>
                                        </LightTooltip>
                                    </Typography>
                            )}
                                wrapped
                            />
                            <Tab label={<Typography>Utilisateurs</Typography>} wrapped />
                        </Tabs>
                    </Grid>
                    {
                        activeTab === 0
                            ? renderOrganisationTab()
                            : activeTab === 1
                                ? renderComputersTab()
                                : renderUsersTab()
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

Administration.propTypes = {
    collectivite: PropTypes.object,
};

export default Administration;
