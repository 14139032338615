import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

const ContentHeader = (props) => {
    const { libelle } = props;

    return (
        <Typography
            variant='h5'
            style={{
                borderLeft: '5px solid #0064AE',
                paddingLeft: '0.5em',
            }}
        >
            {libelle}
        </Typography>
    );
};

ContentHeader.propTypes = {
    libelle: PropTypes.string,
};

export default ContentHeader;
