import { withStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';

const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[5],
        fontSize: '1em',
        maxWidth: 'none'
    },
}))(Tooltip);

export default LightTooltip;