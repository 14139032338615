import React from 'react';
import PropTypes from 'prop-types';

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
    Button,
    Link,
    Grid,
} from '@material-ui/core';

import LogContext from '../Context/LogContext';
import { getRdp } from '../../api';
import { InfoOutlined } from '@material-ui/icons';
import LightTooltip from '../LightTooltip';

const DialogDownloadTool = (props) => {
    const {
        libellePoste,
        buttonVisible,
        identifiantRg,
    } = props;

    const { token, isAuthenticationValid } = React.useContext(LogContext);

    const [
        open,
        setOpen,
    ] = React.useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleDownload = () => {
        fetch(getRdp(identifiantRg), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((data) => {
                        window.open(data.url);
                    })
                }
            }
        });
    }

    return (
        <>
            <Button
                disableRipple
                onClick={handleOpen}
                color='primary'
                style={{ visibility: buttonVisible ? 'visible' : 'hidden' }}
            >
                Récupérer le lien d'accès
                <LightTooltip
                    placement='bottom'
                    title='Le poste qui contrôle à distance le poste hôte'
                >
                    <span style={{color: "#1976d2", whiteSpace: 'pre-wrap'}}> « Client »<InfoOutlined style={{fontSize: '1rem'}} /> </span>
                </LightTooltip>
                associé
            </Button>
            <Dialog onClose={handleClose} open={open} maxWidth='md'>
                <DialogTitle onClose={handleClose}>
                    Récupérez le lien d'accès du poste
                    {' '}
                    {libellePoste}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={4}>
                        <Grid item>
                            <Typography>
                                Pour prendre le contrôle à distance du poste « { libellePoste } »,
                                téléchargez l’outil « Client de connexion » grâce au lien ci-dessous.
                            </Typography>
                        </Grid>
                        <Grid item container justify='center'>
                                <Link
                                    variant='body2'
                                    onClick={handleDownload}
                                    style={{cursor: 'pointer'}}
                                >
                                    Récupérer le lien pour télécharger l'outil « Client de connexion » associé à { libellePoste }
                                </Link>
                        </Grid>
                        <Grid item>
                            <Typography>
                                Pour accéder ultérieurement au poste
                                <LightTooltip
                                    placement='bottom'
                                    title='Le poste qui sera contrôlé à distance par le poste client'
                                >
                                    <span style={{color: "#1976d2"}}> « Hôte »<InfoOutlined style={{marginBottom: 5, fontSize: '1rem'}} /> </span>
                                </LightTooltip>
                                depuis cet ordinateur,
                                il vous suffira d’exécuter à nouveau cet outil.
                                Vous n’aurez pas besoin de le retélécharger à chaque fois.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: 'grey' }}>
                                NOTE :
                            </Typography>
                            <Typography style={{ color: 'grey' }}>
                                Personne ne pourra prendre le contrôle à distance du poste
                                <LightTooltip
                                    placement='bottom'
                                    title='Le poste qui sera contrôlé à distance par le poste client'
                                >
                                    <span style={{color: "#1976d2"}}> « Hôte »<InfoOutlined style={{marginBottom: 5, fontSize: '1rem'}} /> </span>
                                </LightTooltip>
                                s’il ne connait pas son mot de passe d’ouverture de session
                                (celui que vous saisissez à chaque fois que vous démarrez votre ordinateur).
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                    <Button
                        disableRipple
                        variant="outlined"
                        onClick={handleClose}
                        color="primary"
                    >
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

DialogDownloadTool.propTypes = {
    libellePoste: PropTypes.string,
    identifiantRg: PropTypes.string,
    buttonVisible: PropTypes.bool,
};

export default DialogDownloadTool;
