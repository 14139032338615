import React from 'react';

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
    Button,
    Link,
} from '@material-ui/core';

const DialogModifyMail = () => {
    const [
        open,
        setOpen,
    ] = React.useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    return (
        <>
            <Button
                disableRipple
                onClick={handleOpen}
                color='primary'
            >
                Modifier
            </Button>
            <Dialog maxWidth='xs' onClose={handleClose} open={open} style={{ textAlign: 'center' }}>
                <DialogTitle onClose={handleClose}>
                    Modifier votre adresse email
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom paragraph>
                        Le type de compte que vous utilisez ne permet pas la modification de votre adresse email.
                    </Typography>
                    <Typography gutterBottom paragraph>
                        Veuillez contacter nos services pour effectuer cette
                        modification en envoyant un email à&nbsp;
                        <Link href="mailto:axel.morlet@jvs.fr" color="inherit" underline='always' rel="noreferrer">support@jvs.fr</Link>
                    </Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                    <Button
                        disableRipple
                        variant="outlined"
                        onClick={handleClose}
                        color="primary"
                    >
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DialogModifyMail;
