import _ from 'lodash';
import React from 'react';

import {
    Box,
    TextField,
    ListItem,
    List,
    InputAdornment,
    IconButton,
    Typography,
    CircularProgress,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
} from '@material-ui/core';
import {
    SearchOutlined,
    Close,
    PersonAdd,
    EditOutlined,
    DeleteOutlined
} from '@material-ui/icons';

import { Waypoint } from 'react-waypoint';

import {
    DashboardContext,
    LogContext,
} from '../Context';

import { 
    customers as getCustomers,
    searchCustomers,
    deleteCustomer,
} from '../../api';
import DialogAddCustomer from '../Dialog/DialogAddCustomer';
import DialogModifyCustomer from '../Dialog/DialogModifyCustomer';
import LightTooltip from '../LightTooltip';
import DialogConfirm from '../Dialog/DialogConfirm';


const DashboardCustomer = () => {
    const [currentPage, setCurrentPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [lastPage, setLastPage] = React.useState(1);
    const [loading, setLoading] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState('');
    const [searchValueAPI, setSearchValueAPI] = React.useState('');
    const [valueSort, setValueSort] = React.useState('name');
    const [openAddCustomer, setOpenAddCustomer] = React.useState(false);
    const [openModifyCustomer, setOpenModifyCustomer] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState();

    const { token, isAuthenticationValid } = React.useContext(LogContext);

    const {
        customers,
        setCustomers,
        activeCustomer,
        setActiveCustomer,
        setUsers,
    } = React.useContext(DashboardContext);

    const { id_customer: identifiantCustomer } = activeCustomer || {};

    const loadCustomers = React.useCallback((page) => {
        setLoading(true);

        fetch(_.isEmpty(searchValueAPI) ? getCustomers(valueSort, page) : searchCustomers(searchValueAPI, valueSort, page), {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((res) => {
                        const {
                            data,
                            meta,
                        } = res;
                        const {
                            currentPage: cp,
                            lastPage: lp,
                            total: tot
                        } = meta;

                        setCustomers((prevState) => ([...prevState, ...data]));
                        setLoading(false);

                        setCurrentPage(cp);

                        if (!_.isEmpty(data) && cp === 1) {
                            setActiveCustomer(data[0]);
                            setTotal(tot);
                            setLastPage(lp);
                        }

                        if (tot === 0) {
                            setActiveCustomer(null);
                        }
                    });
                } else {
                    setActiveCustomer(null);
                    setLoading(false);
                }
            }
        });
    }, [
        isAuthenticationValid,
        searchValueAPI,
        setActiveCustomer,
        setCustomers,
        token,
        valueSort,
    ]);

    React.useEffect(() => {
        setCustomers([]);
        loadCustomers(1);
    }, [loadCustomers, setCustomers, valueSort]);

    const handleCustomerLoad = () => {
        if (currentPage > 0) {
            loadCustomers(currentPage + 1);
        }
    };

    const handleChange = (e) => {
        const { target } = e || {};
        const { value } = target || {};

        setSearchValue(value);
    };

    const handleSearchReset = () => {
        setSearchValue('');
        setSearchValueAPI('');
        setCustomers([]);
        setCurrentPage(0);
        setTotal(0);
        setLastPage(1);
        setActiveCustomer(null);
    };

    const handleListItemClick = _.debounce(((c) => {
        if(!_.isEqual(c, activeCustomer)){ 
            setActiveCustomer(c); 
            setUsers(null)
        }
    }), 300);

    const leftCustomers = [];
    const rightCustomers = [];
    const displayedCustomers = customers;

    _.forEach(displayedCustomers, (customer, index) => {
        if (index % 2 === 0 || index === 0) {
            leftCustomers.push(customer);
        } else {
            rightCustomers.push(customer);
        }
    });

    const handleSearch = () => {
        setSearchValueAPI(searchValue);
        setCustomers([]);
        setCurrentPage(0);
        setTotal(0);
        setLastPage(1);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleChangeSort = (e, value) => setValueSort(value)

    const handleMoveWithArrow = ({ key }) => {
        if(_.find(leftCustomers, customer => customer.id_customer === identifiantCustomer)){
            const index = _.findIndex(leftCustomers, customer => customer.id_customer === identifiantCustomer);

            switch (key) { 
                case 'ArrowUp':
                    if (index !== 0){
                        setActiveCustomer(leftCustomers[index - 1]);
                    }
                    break;
                case 'ArrowDown':
                    if(_.size(leftCustomers) > index + 1){
                        setActiveCustomer(leftCustomers[index + 1]);
                    }
                    break;
                case 'ArrowRight':
                    if(_.size(rightCustomers) > index){
                        setActiveCustomer(rightCustomers[index]);
                    }
                    break;
                default:
                    break;  
            }
        } else {
            const index = _.findIndex(rightCustomers, customer => customer.id_customer === identifiantCustomer);

            switch (key) { 
                case 'ArrowUp':
                    if (index !== 0){
                        setActiveCustomer(rightCustomers[index - 1]);
                    }
                    break;
                case 'ArrowDown':
                    if(_.size(rightCustomers) > index + 1){
                        setActiveCustomer(rightCustomers[index + 1]);
                    }
                    break;
                case 'ArrowLeft':
                    setActiveCustomer(leftCustomers[index]);
                    break;
                default:
                    break;  
            }
        }
    }

    const handleOpenAddcustomer = () => setOpenAddCustomer(true);

    const handleCloseAddCustomer = () => setOpenAddCustomer(false);

    const handleOpenModifyCustomer = () => setOpenModifyCustomer(true);

    const handleCloseModifyCustomer = () => setOpenModifyCustomer(false);

    const handleOpenConfirmDelete = (e, id) => {
        e.stopPropagation();

        setOpenConfirm(id);
    }

    const handleSubmit = () => {
        setOpenAddCustomer(false);
        setOpenModifyCustomer(false)
        setCustomers([]);
        setCurrentPage(0);
        setTotal(0);
        setLastPage(1);
        loadCustomers(1);
    }

    const handleDeleteCustomer = (identifiantCustomer) => {
        setLoading(true);
        fetch(deleteCustomer(identifiantCustomer), {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then(() => {
                        // setReload(true); TODO
                        setLoading(false);
                        setOpenConfirm();
                    });
                }
            }
        });
    }

    return (
        <Box style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <Box style={{ paddingBottom: '1em', display: 'flex', alignItems: 'center',justifyContent: 'space-between' }}>
                <Box>
                    <Typography
                        variant='h6'
                        style={{
                            borderLeft: '5px solid #0064AE',
                            paddingLeft: '0.5em',
                        }}
                    >
                        Liste des clients
                    </Typography>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <FormLabel component="legend" style={{ paddingRight: '0.5em' }}>Trier par</FormLabel>
                    <RadioGroup value={valueSort} row onChange={handleChangeSort}>
                        <FormControlLabel value="name" control={<Radio disableRipple color="primary" />} label="Nom" />
                        <FormControlLabel value="external_id" control={<Radio disableRipple color="primary" />} label="Identifiant Knote" />
                    </RadioGroup>
                </Box>
                <Box>
                    <TextField
                        autoComplete='off'
                        size='small'
                        variant="outlined"
                        placeholder='Rechercher un client'
                        style={{ float: 'right', width: 350 }}
                        onChange={handleChange}
                        value={searchValue}
                        onKeyPress={handleKeyPress}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {!_.isEmpty(searchValue) && (
                                        <IconButton
                                            disableRipple
                                            size='small'
                                        >
                                            <Close onClick={handleSearchReset} />
                                        </IconButton>
                                    )}
                                    <IconButton
                                        disableRipple
                                        size='small'
                                    >
                                        <SearchOutlined onClick={handleSearch} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
                <Box
                    onKeyDown={handleMoveWithArrow}
                    style={{
                        flex: 1,
                        overflowY: 'auto',
                        marginBottom: '0.5em',
                    }}
                >
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                    >
                        <Box style={{ order: 1, flex: 1 }}>
                            <List>
                                {_.map(leftCustomers, (customer) => {
                                    const {
                                        name,
                                        id_node: identifiantNode,
                                        id_customer: idCustomer,
                                        external_id: idExterne,
                                    } = customer;

                                    const label = _.isNil(idExterne)
                                    ? `${name} (${identifiantNode})`
                                    : `${idExterne} - ${name} (${identifiantNode})`;
                                    
                                    return (
                                        <ListItem
                                            disableRipple
                                            button
                                            selected={identifiantCustomer === idCustomer}
                                            onClick={() => handleListItemClick(customer)}
                                            style={{display: 'flex', cursor: loading ? 'wait': 'pointer'}}
                                        >
                                            <Box style={{ flex: 1 }}>{label}</Box>
                                            {identifiantCustomer === idCustomer && (
                                                <>
                                                    <LightTooltip title="Modifier le client">
                                                        <Button
                                                            disableRipple
                                                            onClick={handleOpenModifyCustomer}
                                                            style={{ padding: 0 }}
                                                        >
                                                            <EditOutlined fontSize='small'/>
                                                        </Button>
                                                    </LightTooltip>
                                                    
                                                    <LightTooltip title="Supprimer le client">
                                                        <Button
                                                            disableRipple
                                                            onClick={(e) => handleOpenConfirmDelete(e, idCustomer)}
                                                            style={{ padding: 0 }}
                                                        >
                                                            <DeleteOutlined fontSize='small'/>
                                                        </Button>
                                                    </LightTooltip>
                                                </>
                                            )
                                        }
                                        </ListItem>
                                    );
                                })}
                                { !loading && _.isEmpty(leftCustomers) && (
                                    <Typography>
                                        Aucun client trouvé
                                    </Typography>
                                )}
                            </List>
                        </Box>
                        <Box style={{ order: 2, flex: 1 }}>
                            <List>
                                {_.map(rightCustomers, (customer) => {
                                    const {
                                        name,
                                        id_customer: idCustomer,
                                        id_node: identifiantNode,
                                        external_id: idExterne,
                                    } = customer;
                                    
                                    const label = _.isNil(idExterne)
                                    ? `${name} (${identifiantNode})`
                                    : `${idExterne} - ${name} (${identifiantNode})`;
                                    
                                    return (
                                        <ListItem
                                            disableRipple
                                            button
                                            selected={identifiantCustomer === idCustomer}
                                            onClick={() => handleListItemClick(customer)}
                                            style={{display: 'flex', cursor: loading ? 'wait': 'pointer'}}
                                        >
                                            <Box style={{ flex: 1 }}>{label}</Box>
                                            {identifiantCustomer === idCustomer && (
                                                <>
                                                  <LightTooltip title="Modifier le client">
                                                      <Button
                                                          disableRipple
                                                          onClick={handleOpenModifyCustomer}
                                                          style={{ padding: 0 }}
                                                      >
                                                          <EditOutlined fontSize='small'/>
                                                      </Button>
                                                  </LightTooltip>
                                                  
                                                  <LightTooltip title="Supprimer le client">
                                                      <Button
                                                          disableRipple
                                                          onClick={(e) => handleOpenConfirmDelete(e, idCustomer)}
                                                          style={{ padding: 0 }}
                                                      >
                                                          <DeleteOutlined fontSize='small'/>
                                                      </Button>
                                                  </LightTooltip>
                                                </>
                                            )
                                        }
                                        </ListItem>
                                    );
                                })}
                            </List>
                            { currentPage > 0 && currentPage < lastPage && (
                                <Waypoint
                                    key={`WayPoint${currentPage}`}
                                    fireOnRapidScroll
                                    onEnter={handleCustomerLoad}
                                />
                            )}  
                        </Box>
                    </Box>
                    <Box style={{ textAlign: 'center' }}>
                        { loading && <CircularProgress size={24} /> }
                    </Box>
                </Box>
            <Box>
                <Button
                    disableRipple
                    onClick={handleOpenAddcustomer}
                    variant="contained"
                    color='primary'
                    startIcon={<PersonAdd />}
                    size='small'
                >
                    Ajouter un client
                </Button>
                {!_.isEmpty(displayedCustomers) && !_.isNil(activeCustomer) && (
                        <Typography style={{
                            fontSize: '1rem',
                            fontWeight: 400,
                            lineHight: 1.43,
                            color: 'rgba(0, 0, 0, 0.54)',
                            float: 'right',
                        }}
                        >
                            {`${total} clients`}
                        </Typography>
                )}
            </Box>
            {openAddCustomer && (
                <DialogAddCustomer
                    onClose={handleCloseAddCustomer}
                    onSubmit={handleSubmit}
                />
            )}
            {openModifyCustomer && (
                <DialogModifyCustomer
                    onClose={handleCloseModifyCustomer}
                    onSubmit={handleSubmit}
                    customer={activeCustomer}
                />
            )}
            {!_.isNil(openConfirm) && (
                <DialogConfirm
                    text='Êtes-vous sûr de vouloir supprimer ce client ?'
                    onConfirm={handleDeleteCustomer}
                    onCancel={() => setOpenConfirm(null)}
                    loading={loading}
                />
            )}
        </Box>
    );
};

export default DashboardCustomer;
