import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import {
    Tabs,
    Tab,
    Typography,
    Grid,
    Box,
    Button,
    CircularProgress,
} from '@material-ui/core';

import {
    ExitToApp,
    ArrowDropDown,
    MoreHoriz,
} from '@material-ui/icons';

import mpadIcon from '../../assets/images/mpad-icon-inverted.png';
import mpad from '../../assets/images/mpad-inverted.png';
import DialogChoiceCustomer from '../Authentification/DialogChoiceCustomer';

import { LogContext } from '../Context';
import { token as getToken } from '../../api';
import LightTooltip from '../LightTooltip';
import ApplicationMenuUser from './ApplicationMenuUser';
import ApplicationMenuAdminCim from './ApplicationMenuAdminCim';

const ApplicationMenu = (props) => {
    const {
        onChange,
        firstConnexion,
        compact,
        collectivites,
        login,
        onAuth,
        onDisconnect,
        activeCollectiviteName,
        loading,
        adminCim,
        activeMenu,
    } = props;

    const {
        token,
        activeUser,
        setToken,
        isAuthenticationValid,
    } = React.useContext(LogContext);

    const { role: administrateur } = activeUser || {};

    const [activeTab, setActiveTab] = React.useState(activeMenu);

    const [modal, setModal] = React.useState(false);
    const [collectiviteLoading, setCollectiviteLoading] = React.useState(false);

    const handleChange = (e, index) => {
        setActiveTab(index);

        if (onChange) {
            onChange(index);
        }
    };

    const handleDisconnect = () => {
        if (onDisconnect) onDisconnect();
    };

    const handleCollectiviteChange = () => setModal(true);

    const handleCloseModal = () => setModal(false);

    const handleCollectiviteSubmit = ({ identifiant }) => {
        setCollectiviteLoading(true);

        fetch(getToken(identifiant, login),
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((data) => {
                        const { token: tok, first } = data;

                        if (!_.isNil(tok) && tok !== 'token') {
                            setToken(tok);

                            if (first) {
                                onAuth(login, null, true, tok);
                            } else {
                                onAuth(login, null, false, tok);
                            }
                        }

                        setModal(false);
                        setCollectiviteLoading(false);
                    });
                } else {
                    setModal(false);
                    setCollectiviteLoading(false);
                }
            }
        });
    };

    const renderLabel = (Comp, label) => (
        !compact ? (
            <Typography variant='body2'>
                <Comp style={{
                    verticalAlign: 'middle',
                    paddingRight: '10px',
                }}
                />
                {label}
            </Typography>
        )
            : <Comp />
    );

    const renderContent = () => (
        <Grid
            item
            style={{
                flex: '1',
                width: '100%',
                paddingTop: compact ? '5.5rem' : '3.5rem',
            }}
        >
            <Tabs
                className='ApplicationMenuTabs'
                orientation="vertical"
                value={activeTab}
                indicatorColor='inherit'
            >
                <Grid
                    {...compact ? { className: 'compact' } : {}}
                    container
                    direction="column"
                    justify="space-between"
                    alignItems='flex-end'
                    spacing={8}
                    style={{ height: '100%' }}
                >
                    {adminCim
                        ? <ApplicationMenuAdminCim 
                                activeTab={activeTab}
                                labelRenderer={renderLabel}
                                onTabClick={handleChange}
                                compact={compact}
                            />
                        : (
                            <ApplicationMenuUser
                                activeTab={activeTab}
                                administrateur={administrateur}
                                labelRenderer={renderLabel}
                                onTabClick={handleChange}
                                compact={compact}
                            />
                        )
                    }
                    <Grid item>
                        {compact
                            ? (
                                <LightTooltip title='Déconnexion'>
                                    <Tab
                                        disableRipple
                                        selected={activeTab === 4}
                                        onClick={handleDisconnect}
                                        label={renderLabel(ExitToApp, 'Déconnexion')}
                                        style={{ minWidth: 70 }}
                                    />
                                </LightTooltip>
                            )
                            : (
                                <Tab
                                    disableRipple
                                    selected={activeTab === 4}
                                    onClick={handleDisconnect}
                                    label={renderLabel(ExitToApp, 'Déconnexion')}
                                    style={{ minWidth: 70 }}
                                />
                            )}
                    </Grid>
                </Grid>
            </Tabs>
        </Grid>
    );

    const footer = (
        <Grid
            item
            style={{
                textAlign: 'center',
                padding: compact ? '0.1em' : '1em',
                width: '100%',
            }}
        >
            {compact
                ? !_.isNil(collectivites)
                    ? (
                        <LightTooltip title={`Connecté à : ${activeCollectiviteName}`}>
                            <Tab
                                disableRipple
                                onClick={handleCollectiviteChange}
                                label={renderLabel(MoreHoriz, activeCollectiviteName)}
                                style={{ minWidth: 70 }}
                            />
                        </LightTooltip>
                    ) : (
                        <LightTooltip title={`Connecté à : ${activeCollectiviteName}`}>
                            <Tab
                                disableRipple
                                label={renderLabel(MoreHoriz, activeCollectiviteName)}
                                style={{ minWidth: 70, cursor: 'auto' }}
                            />
                        </LightTooltip>
                    )
                : !_.isNil(collectivites)
                    ? (
                        <Button
                            variant='contained'
                            color='primary'
                            disableRipple
                            onClick={handleCollectiviteChange}
                            endIcon={<ArrowDropDown />}
                            fullWidth
                            style={{
                                textTransform: 'none',
                                boxShadow: 'none',
                            }}
                        >
                            <Typography variant="h6">
                                {activeCollectiviteName}
                            </Typography>
                        </Button>
                    ) : (
                        <Typography variant="h6">
                            {activeCollectiviteName}
                        </Typography>
                    )}
        </Grid>
    );

    const header = (
        compact
            ? (
                <img
                    alt='logo'
                    src={mpadIcon}
                    style={{
                        width: 70, padding: '1em',
                    }}
                />
            )

            : (
                <img
                    alt='logo'
                    src={mpad}
                    style={{
                        width: 220, padding: '1em',
                    }}
                />
            )
    );

    return (
        <>
            <Box color='white' style={{ height: '100%' }} bgcolor="primary.main">
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems='flex-start'
                    style={{ height: '100%' }}
                >
                    <Grid
                        item
                        style={{ width: '100%' }}
                    >
                        {header}
                    </Grid>
                    { loading && <CircularProgress size={24} color='white' style={{ margin: 'auto' }} /> }
                    { !loading && !firstConnexion && renderContent()}
                    { !firstConnexion && !adminCim && footer}
                </Grid>
            </Box>
            {modal && (
                <DialogChoiceCustomer
                    onClose={handleCloseModal}
                    items={collectivites}
                    onSelect={handleCollectiviteSubmit}
                    loading={collectiviteLoading}
                />
            )}
        </>
    );
};

ApplicationMenu.propTypes = {
    activeMenu: PropTypes.number,
    onChange: PropTypes.func,
    firstConnexion: PropTypes.bool,
    compact: PropTypes.bool,
    collectivites: PropTypes.array,
    login: PropTypes.string,
    onAuth: PropTypes.func,
    onDisconnect: PropTypes.func,
    activeCollectiviteName: PropTypes.string,
    loading: PropTypes.bool,
    adminCim: PropTypes.bool,
};

export default ApplicationMenu;
