import React from 'react';

import {
    Tab,
    Grid,
} from '@material-ui/core';

import {
    Tv,
    Adjust
} from '@material-ui/icons';

import LightTooltip from '../LightTooltip';

const ApplicationMenuAdminCim = (props) => {
    const {
        activeTab,
        compact,
        labelRenderer,
        onTabClick,
    } = props;

    const handleChange = (event, index) => {
        if (onTabClick) {
            onTabClick(event, index);
        }
    }

    return (
        <>
            <Grid
                item
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {compact
                    ? (
                        <>
                            <LightTooltip title='Dashboard'>
                                <Tab
                                    disableRipple
                                    selected={activeTab === 0}
                                    onClick={(e) => handleChange(e, 0)}
                                    label={labelRenderer(Tv, 'Dashboard')}
                                    style={{ minWidth: 70 }}
                                />
                            </LightTooltip>
                            <LightTooltip title='Procédures exceptionnelles'>
                                <Tab
                                    disableRipple
                                    selected={activeTab === 1}
                                    onClick={(e) => handleChange(e, 1)}
                                    label={labelRenderer(Adjust, 'Procédures')}
                                    style={{ minWidth: 70 }}
                                />
                            </LightTooltip>
                        </>
                    )
                    : (
                        <>
                            <Tab
                                disableRipple
                                selected={activeTab === 0}
                                onClick={(e) => handleChange(e, 0)}
                                label={labelRenderer(Tv, 'Dashboard')}
                                style={{ minWidth: 70 }}
                            />
                            <Tab
                                disableRipple
                                selected={activeTab === 1}
                                onClick={(e) => handleChange(e, 1)}
                                label={labelRenderer(Adjust, 'Procédures')}
                                style={{ minWidth: 70 }}
                            />
                        </>
                    )}
            </Grid>
        </>
    );
};

export default ApplicationMenuAdminCim;