import React from 'react';

import {
    Tab,
    Grid,
} from '@material-ui/core';

import {
    Tv,
    Person,
    Settings,
    HelpOutline,
} from '@material-ui/icons';

import LightTooltip from '../LightTooltip';

const ApplicationMenuUser = (props) => {
    const {
        administrateur,
        activeTab,
        compact,
        labelRenderer,
        onTabClick,
    } = props;

    const handleChange = (event, index) => {
        if (onTabClick) {
            onTabClick(event, index);
        }
    }

    return (
        <>
            <Grid
                item
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end'
                }}
            >
                {compact
                    ? (
                        <>
                            <LightTooltip title='Mes postes'>
                                <Tab
                                    disableRipple
                                    selected={activeTab === 0}
                                    onClick={(e) => handleChange(e, 0)}
                                    label={labelRenderer(Tv, 'Mes postes')}
                                    style={{ minWidth: 70 }}
                                />
                            </LightTooltip>
                            <LightTooltip title='Mon compte'>
                                <Tab
                                    disableRipple
                                    selected={activeTab === 1}
                                    onClick={(e) => handleChange(e, 1)}
                                    label={labelRenderer(Person, 'Mon compte')}
                                    style={{ minWidth: 70 }}
                                />
                            </LightTooltip>
                        </>
                    )
                    : (
                        <>
                            <Tab
                                disableRipple
                                selected={activeTab === 0}
                                onClick={(e) => handleChange(e, 0)}
                                label={labelRenderer(Tv, 'Mes postes')}
                                style={{ minWidth: 70 }}
                            />
                            <Tab
                                disableRipple
                                selected={activeTab === 1}
                                onClick={(e) => handleChange(e, 1)}
                                label={labelRenderer(Person, 'Mon compte')}
                                style={{ minWidth: 70 }}
                            />
                        </>
                    )}
            </Grid>
            <Grid item>
                {compact
                    ? (
                        <LightTooltip title='FAQ'>
                            <Tab
                                disableRipple
                                selected={activeTab === 2}
                                onClick={(e) => handleChange(e, 2)}
                                label={labelRenderer(HelpOutline, 'FAQ')}
                                style={{ minWidth: 70 }}
                            />
                        </LightTooltip>
                    )
                    : (
                        <Tab
                            disableRipple
                            selected={activeTab === 2}
                            onClick={(e) => handleChange(e, 2)}
                            label={labelRenderer(HelpOutline, 'FAQ')}
                            style={{ minWidth: 70 }}
                        />
                    )}
            </Grid>
            <Grid item>
                { administrateur
                    ? compact
                        ? (
                            <LightTooltip title='Administration'>
                                <Tab
                                    disableRipple
                                    selected={activeTab === 3}
                                    onClick={(e) => handleChange(e, 3)}
                                    label={labelRenderer(Settings, 'Administration')}
                                    style={{ minWidth: 70 }}
                                />
                            </LightTooltip>
                        ) : (
                            <Tab
                                disableRipple
                                selected={activeTab === 3}
                                onClick={(e) => handleChange(e, 3)}
                                label={labelRenderer(Settings, 'Administration')}
                                style={{ minWidth: 70 }}
                            />
                        )
                    : null}
            </Grid>
        </>
    );
};

export default ApplicationMenuUser;