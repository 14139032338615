import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    Button,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Typography,
    TableBody,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 'auto',
    },
    paper: {
        width: 300,
        height: 330,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

const TransferList = (props) => {
    const {
        left,
        right,
        leftTitle,
        rightTitle,
        onChange,
    } = props;

    const [
        selectedLeft,
        setSelectedLeft,
    ] = React.useState([]);

    const [
        selectedRight,
        setSelectedRight,
    ] = React.useState([]);

    const classes = useStyles();

    React.useEffect(() => {
        setSelectedLeft([]);
    }, [left]);

    React.useEffect(() => {
        setSelectedRight([]);
    }, [right]);

    const addSelectedToState = (index, items, setItems) => {
        const currentIndex = _.indexOf(items, index);

        const newChecked = [...items];

        if (currentIndex === -1) {
            newChecked.push(index);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setItems(newChecked);
    };

    const handleSelectRow = (e, index, side) => {
        e.stopPropagation();
        
        if (side === 'left') {
            addSelectedToState(index, selectedLeft, setSelectedLeft);
        } else {
            addSelectedToState(index, selectedRight, setSelectedRight);
        }
    };

    const handleMoveToLeft = (e) => {
        e.stopPropagation();

        if (onChange) { onChange(_.filter(right, (obj, index) => _.includes(selectedRight, index)), 'left'); }
    };

    const handleMoveToRight = (e) => {
        e.stopPropagation();
        
        if (onChange) { onChange(_.filter(left, (obj, index) => _.includes(selectedLeft, index)), 'right'); }
    };

    const renderLeftList = () => (
        <Paper className={classes.paper}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant='body2'>{leftTitle}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {_.map(left, (item, index) => (
                        <TableRow key={`leftTable${item.identifiant}`} onClick={(e) => handleSelectRow(e, index, 'left')} selected={_.indexOf(selectedLeft, index) !== -1}>
                            <TableCell>
                                <Typography>
                                    {item.libelle}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
    const renderRightList = () => (
        <Paper className={classes.paper}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant='body2'>{rightTitle}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {_.map(right, (item, index) => (
                        <TableRow key={`rightTable${item.identifiant}`} onClick={(e) => handleSelectRow(e, index, 'right')} selected={_.indexOf(selectedRight, index) !== -1}>
                            <TableCell>
                                <Typography>
                                    {item.libelle}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );

    const renderButton = () => (
        <Grid container direction="column" alignItems="center">
            <Button
                disableRipple
                variant="outlined"
                size="small"
                onClick={handleMoveToRight}
                disabled={_.size(left) === 0 || _.size(selectedLeft) === 0}
                aria-label="move all right"
            >
                ≫
            </Button>
            <Button
                disableRipple
                variant="outlined"
                size="small"
                onClick={handleMoveToLeft}
                disabled={_.size(right) === 0 || _.size(selectedRight) === 0}
                aria-label="move all left"
            >
                ≪
            </Button>
        </Grid>
    );

    return (
        <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
            <Grid item>{renderLeftList()}</Grid>
            <Grid item>{renderButton()}</Grid>
            <Grid item>{renderRightList()}</Grid>
        </Grid>
    );
};

TransferList.propTypes = {
    left: PropTypes.array,
    right: PropTypes.array,
    leftTitle: PropTypes.string,
    rightTitle: PropTypes.string,
    onChange: PropTypes.func,
};

export default TransferList;
