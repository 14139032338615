import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
    Grid,
    Typography,
    TextField,
    Button,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    RootRef,
    MenuItem,
    InputAdornment,
    IconButton,
    CircularProgress,
} from '@material-ui/core';
import {
    PersonAdd,
    ChevronRight,
    Pause,
    MoreHoriz,
    InfoOutlined,
} from '@material-ui/icons';

import LogContext from '../../Context/LogContext';
import { inviteUser, counter } from '../../../api';
import ContentHeader from '../ContentHeader';
import UserList from './UserList';
import LightTooltip from '../../LightTooltip';

const defaultItem = {
    id: null,
    last_name: '',
    first_name: '',
    email: '',
    role: false,
    cim: false,
};

const optionsRoles = [
    {
        value: true,
        label: 'Administrateur',
    },
    {
        value: false,
        label: 'Utilisateur',
    },
];

const Invitation = (props) => {
    const { onNext } = props;

    const { token, isAuthenticationValid, activeUser} = React.useContext(LogContext);

    const [items, setItems] = React.useState(_.times(
        5,
        () => ({ ...defaultItem, id: _.uniqueId() }),
    ));
    const [usersModalOpen, setUsersModalOpen] = React.useState();
    const [compteurs, setCompteurs] = React.useState();
    const [loading, setLoading] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = React.useState(false);

    const { total } = compteurs || {};

    const tableRef = React.useRef();
    const itemsSize = React.useRef(_.size(items));

    React.useEffect(() => {
        if (itemsSize.current < _.size(items)) {
            if (tableRef.current) {
                tableRef.current.scrollTo({
                    top: tableRef.current.scrollHeight,
                    behavior: 'smooth',
                });
            }
        }

        if (_.size(items) < 5) {
            setItems([...items, { ...defaultItem, id: _.uniqueId() }]);
        }

        itemsSize.current = _.size(items);
    }, [items]);

    React.useEffect(() => {
        fetch(counter(),
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((data) => {
                        setCompteurs(data);
                    });
                }
            }
        });
    }, [isAuthenticationValid, token]);

    const handleAddRow = () => {
        setItems([...items, { ...defaultItem, id: _.uniqueId() }]);
    };

    // const handlePrevious = () => {
    //     if (onPrevious) onPrevious();
    // };

    const invitationsToSend = _.filter(
        items,
        (item) => !_.isEmpty(item.email)
        && !_.isEmpty(item.first_name)
        && !_.isEmpty(item.last_name),
    );

    const hasCompleteInvitation = !_.isEmpty(invitationsToSend);

    const handleNext = () => {
        setLoading(true);

        const pendingPromises = [];

        _.map(invitationsToSend, (invite) => {
            pendingPromises.push(
                fetch(inviteUser(), {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(invite),
                }).then((response) => {
                    if (isAuthenticationValid(response)) {
                        if (response.status && response.status !== 200) {
                            response.json().then(() => {
                                setError(true);
                            });
                        }
                    }
                }),
            );
        });

        Promise.all(pendingPromises).then(() => {
            setLoading(false);
            setError(false); // TODO

            if (onNext) onNext();
        });
    };

    const handleSkip = () => {
        if (onNext) onNext();
    };

    const handleChange = (id) => (event) => {
        const { target } = event || {};
        const {
            name,
            value,
        } = target || {};

        const indexUser = _.findIndex(items, (user) => user.id === id);

        if (indexUser >= 0) {
            const copy = [...items];

            if (!_.isEqual(copy[indexUser][name], value)) {
                copy[indexUser][name] = value;

                setItems(copy);
            }
        }
    };

    const handleUsersModalOpen = (id) => {
        setUsersModalOpen(id);
    };

    const handleUserSelect = (user) => {
        if (user) {
            // si c'est plus l'id généré par uniqueId
            if (usersModalOpen !== user.id) {
                const index = _.findIndex(items, (u) => u.id === usersModalOpen);

                const copy = [...items];
                copy[index] = {
                    ...copy[index],
                    ...user,
                    cim: true,
                };

                setItems(copy);
                setUsersModalOpen(null);
            }
        }
    };

    const handleUsersModalClose = () => {
        setUsersModalOpen(null);
    };

    const handleRowRemove = (id) => () => {
        setItems(_.filter(items, (item) => item.id !== id));
    };

    const renderRow = (item) => (
        <TableRow key={`TableRow${item.id}`}>
            <TableCell style={{ width: '20%' }}>
                <TextField
                    autoComplete='off'
                    key='last_name'
                    name='last_name'
                    size='small'
                    fullWidth
                    variant="outlined"
                    value={_.toUpper(item.last_name)}
                    onChange={handleChange(item.id)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton disableRipple
                                    size='small'
                                    onClick={() => handleUsersModalOpen(item.id)}
                                >
                                    <MoreHoriz />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </TableCell>
            <TableCell style={{ width: '20%' }}>
                <TextField
                    autoComplete='off'
                    name='first_name'
                    size='small'
                    fullWidth
                    variant="outlined"
                    value={item.first_name}
                    onChange={handleChange(item.id)}
                />
            </TableCell>
            <TableCell style={{ width: '35%' }}>
                <TextField
                    autoComplete='off'
                    name='email'
                    size='small'
                    fullWidth
                    variant="outlined"
                    value={item.email}
                    onChange={handleChange(item.id)}
                />
            </TableCell>
            <TableCell style={{ width: '15%' }}>
                <TextField
                    autoComplete='off'
                    size='small'
                    fullWidth
                    variant="outlined"
                    select
                    value={item.role}
                    name='role'
                    onChange={handleChange(item.id)}
                >
                    {_.map(optionsRoles, (role) => (
                        <MenuItem
                            key={role.value}
                            value={role.value}
                        >
                            {role.label}
                        </MenuItem>
                    ))}
                </TextField>
            </TableCell>
            <TableCell style={{ width: '10%' }}>
                <Button
                    color='primary'
                    tabIndex={-1}
                    onClick={handleRowRemove(item.id)}
                >
                    effacer
                </Button>
            </TableCell>
        </TableRow>
    );

    const renderTable = () => (
        <>
            <RootRef rootRef={tableRef}>
                <TableContainer
                    key='Table'
                    style={{
                        height: 420,
                        overflowY: 'scroll',
                    }}
                >
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nom</TableCell>
                                <TableCell>Prénom</TableCell>
                                <TableCell>Adresse email</TableCell>
                                <TableCell>
                                    <LightTooltip
                                        placement='bottom'
                                        title={
                                            <>
                                                <Typography style={{ whiteSpace: 'pre-wrap' }}>
                                                    <b>Utilisateur :</b>
                                                    <ul style={{marginTop: 0, listStyleType: '"- "'}}>
                                                        <li>peut ajouter un poste,</li>
                                                        <li>peut gérer les postes sur lesquels il est rattaché,</li>
                                                        <li>peut modifier ses informations personnelles</li>
                                                    </ul>
                                                </Typography>
                                                <br />
                                                <Typography style={{ whiteSpace: 'pre-wrap' }}>
                                                    <b>Administrateur :</b>
                                                    <ul style={{marginTop: 0, listStyleType: '"- "'}}>
                                                        <li>peut ajouter un poste,</li>
                                                        <li>peut gérer les postes sur lesquels il est rattaché,</li>
                                                        <li>peut modifier ses informations personnelles</li>
                                                        <li>peut gérer tous les utilisateurs (invitation, modification, suppression, attacher/détacher des postes)</li>
                                                        <li>peut gérer tous les postes (suppression, attacher/détacher des utilisateurs)</li>
                                                    </ul>
                                                </Typography>
                                            </>
                                        }
                                    >
                                        <span style={{ color: '#1976d2' }}>
                                            Rôle
                                            <InfoOutlined style={{ marginBottom: 5, fontSize: '1rem' }} />
                                        </span>
                                    </LightTooltip>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(items, renderRow)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </RootRef>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell padding='none' style={{ width: '90%' }}>
                            <Button
                                disableRipple
                                color='primary'
                                style={{ width: '100%' }}
                                onClick={handleAddRow}
                            >
                                <PersonAdd fontSize='large' />
                            </Button>
                        </TableCell>
                        <TableCell padding='none' style={{ width: '10%' }} />
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );

    return (
        <Grid container spacing={8}>
            <Grid item xs={12}>
                <ContentHeader libelle='ACTIVATION DE VOTRE ESPACE CLIENT' />
            </Grid>
            <Grid item>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} style={{ paddingTop: '2rem' }}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>
                                    Invitez les autres utilisateurs qui pourront configurer
                                    l’outil « Hôte de connexion » sur leur poste de bureau
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    Votre contrat vous permet de déclarer un maximum de
                                    {total ? ` ${total}` : ' 0'}
                                    {' '}
                                    ordinateurs pour un nombre d’utilisateur illimité.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    Si vous le souhaitez, vous aurez la possibilité
                                    d’inviter d’autres utilisateurs ultérieurement.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    Les utilisateurs que vous indiquerez dans ce tableau
                                    recevront un email d’activation qui leur permettra d’installer
                                    l’outil « Hôte de connexion » sur l’ordinateur dont ils
                                    voudront prendre le contrôle à distance (le poste de bureau).
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems='center'>
                            {renderTable()}
                        </Grid>
                        { !_.isNil(usersModalOpen) && (
                            <UserList
                                onSelect={handleUserSelect}
                                onClose={handleUsersModalClose}
                                selected={[..._.map(_.filter(items, (i) => i.cim), i => i.email),  activeUser.email]}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                            spacing={5}
                        >
                            <Grid item>
                                <Button
                                    disableRipple
                                    onClick={handleNext}
                                    variant="contained"
                                    color='primary'
                                    disabled={loading || !hasCompleteInvitation}
                                    endIcon={loading
                                        ? <CircularProgress size={20} color='white' />
                                        : <ChevronRight />}
                                >
                                    Envoyer les invitations et passer à l&apos;étape suivante
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disableRipple
                                    onClick={handleSkip}
                                    variant="outlined"
                                    color='primary'
                                    startIcon={<Pause />}
                                    disabled={loading}
                                >
                                    Je finirai plus tard
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

Invitation.propTypes = {
    onNext: PropTypes.func,
    onPrevious: PropTypes.func,
};

export default Invitation;
