import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
    Button,
    Grid,
    TextField,
    Tab,
    Tabs,
    MenuItem,
    CircularProgress,
} from '@material-ui/core';
import {
    Check,
    Close,
    DeleteOutlined,
    InfoOutlined,
} from '@material-ui/icons';

import TransferList from '../TransferList/TransferList';
import DialogModifyMail from './DialogModifyMail';
import DialogConfirm from './DialogConfirm';
import LogContext from '../Context/LogContext';
import {
    customerComputers as fetchCustomerComputers,
    userComputers,
    createUserComputer,
    deleteUserComputer,
    userInformations,
    modifyUser,
    customerUserInformations,
    customerUserComputers,
    modifyCustomerUser,
    createCustomerUserComputer,
    deleteCustomerUserComputer,
} from '../../api';
import LightTooltip from '../LightTooltip';

const optionsRoles = [
    {
        value: true, label: 'Administrateur',
    },
    {
        value: false, label: 'Utilisateur',
    },
];

const DialogManageUser = (props) => {
    const {
        libelleUser,
        onSave,
        onUserDelete,
        identifiantUser,
        identifiantCustomer,
        onClose,
    } = props;

    const {
        token,
        isAuthenticationValid,
        refreshActiveUser,
        activeUser
    } = React.useContext(LogContext);

    const { role: roleActiveUser, id_user: identifiantActiveUser } = activeUser || {};

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [activeTab,setActiveTab] = React.useState(0);
    const [customerComputers, setCustomerComputers] = React.useState([]);
    const [computersAvailable,setComputersAvailable] = React.useState([]);
    const [computersAttached,setComputersAttached] = React.useState([]);
    const [computersAttachedInitial,setComputersAttachedInitial] = React.useState([]);
    const [newAttached,setNewAttached] = React.useState([]);
    const [removed,setRemoved] = React.useState([]);
    const [loading,setLoading] = React.useState([]);
    const [prenom,setPrenom] = React.useState('');
    const [nom,setNom] = React.useState('');
    const [mail,setMail] = React.useState('');
    const [role,setRole] = React.useState(false);

    React.useEffect(() => {
        setLoading(prevLoading => _.uniq([...prevLoading, 'userInformations']));

        const route = identifiantCustomer 
            ? customerUserInformations(identifiantCustomer, identifiantUser)
            : userInformations(identifiantUser);

        fetch(route, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((data) => {
                        const {
                            first_name: firstName,
                            last_name: lastName,
                            email,
                            role: roleData,
                        } = data;
                        
                        setPrenom(firstName);
                        setNom(lastName);
                        setMail(email);
                        setRole(roleData);

                        setLoading(prevLoading => ([..._.filter(prevLoading, load => load !== 'userInformations')]));
                    });
                } else {
                    setLoading(prevLoading => ([..._.filter(prevLoading, load => load !== 'userInformations')]));
                }
            }

        });
    }, [identifiantCustomer, identifiantUser, isAuthenticationValid, token])

    const getCustomerComputers = React.useCallback(() => {
        setLoading(prevLoading => _.uniq([...prevLoading, 'postes']));
        
        const route = identifiantCustomer 
            ? fetchCustomerComputers(identifiantCustomer)
            : fetchCustomerComputers();
            
        fetch(route, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((data) => {
                        setCustomerComputers(data);
                    });
                }
            }

            setLoading(prevLoading => ([..._.filter(prevLoading, load => load !== 'postes')]));
        });
    }, [identifiantCustomer, isAuthenticationValid, token]);

    const getComputersAttached = React.useCallback(() => {
        setLoading(prevLoading => _.uniq([...prevLoading, 'postes']));

        const route = identifiantCustomer 
            ? customerUserComputers(identifiantCustomer, identifiantUser)
            : userComputers(identifiantUser);

        fetch(route, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((data) => {
                        const newComputersAttached = _.map(data, ({
                            computer_name: libelle,
                            id_computer_rg: identifiantRg,
                            id_computer: identifiant,
                        }) => ({
                            libelle,
                            identifiant,
                            identifiantRg,
                        }));
                        
                        setComputersAttachedInitial(newComputersAttached);
                        setComputersAttached(newComputersAttached);
                    });
                }
            }

            setLoading(prevLoading => ([..._.filter(prevLoading, load => load !== 'postes')]));
        });
    }, [identifiantCustomer, identifiantUser, isAuthenticationValid, token]);

    React.useEffect(() => {
        getComputersAttached();
    }, [getComputersAttached]);

    React.useEffect(() => {
        getCustomerComputers();
    }, [getCustomerComputers]);

    React.useEffect(() => {
        const availableComputers = _.filter(
            customerComputers,
            d => !_.find(computersAttachedInitial, ca => ca.identifiant === d.id_computer)
        )
        setComputersAvailable(_.map(availableComputers, (ac) => ({
            libelle: ac.computer_name,
            identifiantRg: ac.id_computer_rg,
            identifiant: ac.id_computer,
        })));
    }, [customerComputers, computersAttachedInitial])

    const handleClose = () => { if(onClose) onClose();}

    const handleChangeTab = (e, indice) => setActiveTab(indice);

    const handleTransferListChange = (array, moveTo) => {
        if (moveTo === 'left') {
            setComputersAvailable([
                ...computersAvailable,
                ...array,
            ]);

            const computersAttachedDeleted = _.filter(computersAttached, computer => !_.includes(array, computer));
            const removedComputers = _.filter(computersAttached, computer => _.includes(array, computer));

            setComputersAttached(computersAttachedDeleted);
            setRemoved([
                ...removed,
                ...removedComputers,
            ]);
        } else {
            setComputersAttached([
                ...computersAttached,
                ...array,
            ]);

            const computersAvailableDeleted = _.filter(computersAvailable, computer => !_.includes(array, computer));
            const newComputers = _.filter(computersAvailable, computer => _.includes(array, computer));
            setComputersAvailable(computersAvailableDeleted);
            setNewAttached([
                ...newAttached,
                ...newComputers,
            ]);
        }
    };

    const handleSave = () => {
        setLoading(prevLoading => _.uniq([...prevLoading, 'saving']));

        const pendingPromises = [];

        const routeCreate = identifiantCustomer 
            ? createCustomerUserComputer(identifiantCustomer)
            : createUserComputer();
      
        _.map(newAttached, (computer) => {
            pendingPromises.push(
                fetch(routeCreate, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        id_computer: computer.identifiant,
                        id_user: identifiantUser,
                    }),
                }).then(isAuthenticationValid)
            );
        });

        _.map(removed, (computer) => {
            const routeDelete = identifiantCustomer 
                ? deleteCustomerUserComputer(identifiantCustomer,  identifiantUser, computer.identifiant)
                : deleteUserComputer(computer.identifiant, identifiantUser);
            
            pendingPromises.push(
                fetch(routeDelete, {
                    method: 'DELETE',
                    mode: 'cors',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }).then(isAuthenticationValid)
            );
        });
        
        const body = {
            first_name: prenom,
            last_name: nom,
            role: role,
        };

        const routeUpdate = identifiantCustomer 
            ? modifyCustomerUser(identifiantCustomer, identifiantUser)
            : modifyUser(identifiantUser);
      
        pendingPromises.push(
            fetch(routeUpdate, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(body),
            }).then((response) => {
                if (isAuthenticationValid(response)) {
                    if (response.status && response.status === 200) {
                        response.json().then(refreshActiveUser);
                    }
                }
            })
        );

        Promise.all(pendingPromises).then(() => {
            setLoading(prevLoading => ([..._.filter(prevLoading, load => load !== 'saving')]));
            handleClose();

            if (onSave) onSave();
        });
    }

    const handleDelete = () => setOpenConfirm(true);

    const handleChange = ({ target }, setter) => {
        const { value } = target;

        setter(value);
    };

    const handleDialogCancel = () => setOpenConfirm(false);

    const handleDialogConfirm = () => {
        if (onUserDelete) { onUserDelete(identifiantUser); }

        setOpenConfirm(false);
    }

    const renderTabInformationsUser = () => (
        <>
            <Grid item xs={2}>
                <Typography variant='body2'>Prénom</Typography>
            </Grid>
            <Grid item xs={8}>
                <TextField
                    size='small'
                    fullWidth
                    variant="outlined"
                    value={prenom}
                    onChange={e => handleChange(e, setPrenom)}
                />            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2}>
                <Typography variant='body2'>Nom</Typography>
            </Grid>
            <Grid item xs={8}>
                <TextField
                    size='small'
                    fullWidth
                    variant="outlined"
                    value={nom}
                    onChange={e => handleChange(e, setNom)}
                />            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2}>
                <Typography variant='body2'>Email</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography>{mail}</Typography>
            </Grid>
            <Grid item xs={2} container justify='flex-end'>
                <DialogModifyMail />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2}>
                <Typography variant='body2'>
                    <LightTooltip
                        placement='bottom'
                        title={
                            <>
                                <Typography style={{ whiteSpace: 'pre-wrap' }}>
                                    <b>Utilisateur :</b>
                                    <ul style={{marginTop: 0, listStyleType: '"- "'}}>
                                        <li>peut ajouter un poste,</li>
                                        <li>peut gérer les postes sur lesquels il est rattaché,</li>
                                        <li>peut modifier ses informations personnelles</li>
                                    </ul>
                                </Typography>
                                <br />
                                <Typography style={{ whiteSpace: 'pre-wrap' }}>
                                    <b>Administrateur :</b>
                                    <ul style={{marginTop: 0, listStyleType: '"- "'}}>
                                        <li>peut ajouter un poste,</li>
                                        <li>peut gérer les postes sur lesquels il est rattaché,</li>
                                        <li>peut modifier ses informations personnelles</li>
                                        <li>peut gérer tous les utilisateurs (invitation, modification, suppression, attacher/détacher des postes)</li>
                                        <li>peut gérer tous les postes (suppression, attacher/détacher des utilisateurs)</li>
                                    </ul>
                                </Typography>
                            </>
                        }
                    >
                        <span style={{ color: '#1976d2' }}>
                            Rôle
                            <InfoOutlined style={{ marginBottom: 5, fontSize: '1rem' }} />
                        </span>
                    </LightTooltip>
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <TextField 
                    size='small'
                    value={role}
                    select
                    fullWidth
                    variant="outlined"
                    onChange={e => handleChange(e, setRole)}
                >
                    {_.map(optionsRoles, role => (
                        <MenuItem key={role.value} value={role.value}>
                            {role.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
        </>
    );

    const renderTabComputersAttached = () => (
        <>
            <Grid item xs={12}>
                <Typography>
                    Sélectionnez les postes    
                    <LightTooltip
                        placement='bottom'
                        title='Le poste qui sera contrôlé à distance par le poste client'
                    >
                        <span style={{color: "#1976d2"}}> « Hôte »<InfoOutlined style={{marginBottom: 5, fontSize: '1rem'}} /> </span>
                    </LightTooltip>
                    déjà déclarés
                    que vous souhaitez rattacher au compte de l’utilisateur
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TransferList
                    left={computersAvailable}
                    right={computersAttached}
                    leftTitle='Postes déclarés'
                    rightTitle="Postes rattachés à l'utilisateur"
                    onChange={handleTransferListChange}
                />
            </Grid>
        </>
    );

    return (
        <>
            <Dialog onClose={handleClose} open maxWidth='md'>
                <DialogTitle onClose={handleClose}>
                    Gérer le compte de
                    {' '}
                    {libelleUser}
                </DialogTitle>
                <DialogContent dividers style={{ minWidth: 900 }}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12}>
                            <Tabs
                                value={activeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleChangeTab}
                            >
                                <Tab label={<Typography>Informations de l&apos;utilisateur</Typography>} wrapped />
                                <Tab label={<Typography>Postes rattachés à l&apos;utilisateur</Typography>} wrapped />
                            </Tabs>
                        </Grid>
                        {activeTab === 0
                            ? _.includes(loading, 'userInformations')
                                ? (
                                    <Grid item style={{
                                        display: 'flex',
                                        flex: 1,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 250
                                    }}>
                                        <CircularProgress size={24} /> 
                                    </Grid>
                                )
                                : renderTabInformationsUser()
                            :  _.includes(loading, 'postes')
                                ? (
                                    <Grid item style={{
                                        display: 'flex',
                                        flex: 1,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 250
                                    }}>
                                        <CircularProgress size={24} /> 
                                    </Grid>
                                )
                                : renderTabComputersAttached()
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container>
                        <Grid
                            item
                            xs={4}
                        >
                            { ((identifiantActiveUser !== identifiantUser && roleActiveUser ) || identifiantCustomer) && (
                                <Button
                                    disableRipple
                                    disabled={!_.isEmpty(loading)}
                                    startIcon={<DeleteOutlined />}
                                    variant="outlined"
                                    onClick={handleDelete}
                                    color="secondary"
                                    >
                                    Supprimer cet utilisateur
                                </Button>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            justify="flex-end"
                            container
                        >
                            <Button
                                disableRipple
                                disabled={_.includes(loading, 'saving')}
                                startIcon={<Close />}
                                variant="outlined"
                                onClick={handleClose}
                                color="primary"
                            >
                                Fermer
                            </Button>
                            <Button
                                disableRipple
                                disabled={!_.isEmpty(loading)}
                                startIcon={_.includes(loading, 'saving') ? <CircularProgress size={20} color='white' /> : <Check />}
                                variant="contained"
                                onClick={handleSave}
                                color="primary"
                                style={{ marginLeft: 8 }}
                            >
                                Valider
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            {openConfirm && <DialogConfirm
                text='Êtes-vous sur de vouloir supprimer cet utilisateur'
                onConfirm={handleDialogConfirm}
                onCancel={handleDialogCancel}
                loading={!_.isEmpty(loading)}
            />
            }
        </>
    );
};

DialogManageUser.propTypes = {
    libelleUser: PropTypes.string,
    identifiantUser: PropTypes.number,
    onSave: PropTypes.func,
    onUserDelete: PropTypes.func,
    identifiantCustomer: PropTypes.number,
    onClose: PropTypes.func,
};

DialogManageUser.defaultProps = {
    identifiantCustomer: null,
}

export default DialogManageUser;
