import React from 'react';

import {
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    Tabs,
    Tab,
    Box,
} from '@material-ui/core';
import { ExpandMore, InfoOutlined } from '@material-ui/icons';

import AddFirstComputer from './AddFirstComputer';
import AddNewComputer from './AddNewComputer';
import LightTooltip from '../../LightTooltip';

const AddComputer = (props) => {
    const [activeTab, setActiveTab] = React.useState(0);

    const handleChangeTab = (e, indice) => { setActiveTab(indice); };

    return (
        <ExpansionPanel {...props}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant='h6'>
                    ... Ajouter un poste 
                    <LightTooltip
                        placement='bottom'
                        title='Le poste qui sera contrôlé à distance par le poste client'
                    >
                        <span style={{ color: '#1976d2' }}>
                            {' '}
                            « Hôte »
                            <InfoOutlined style={{ marginBottom: 5, fontSize: '1rem' }} />
                            {' '}
                        </span>
                    </LightTooltip>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Box style={{display: 'flex', flexDirection: 'column' }}>
                    <Tabs
                        value={activeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTab}
                        >
                        <Tab label={<Typography>Premier poste</Typography>} wrapped />
                        <Tab label={<Typography>Nouveau poste</Typography>} wrapped />
                    </Tabs>
                    { activeTab === 0 && <AddFirstComputer />}
                    { activeTab === 1 && <AddNewComputer />}
                </Box>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default AddComputer;
