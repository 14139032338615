import _ from 'lodash';
import React from 'react';

import {
    Grid,
    Backdrop,
    CircularProgress,
} from '@material-ui/core';

import ContentHeader from '../../Content/ContentHeader';
import UpdateRdpLinks from './UpdateRdpLinks';

import { 
    customers as getCustomers,
    customerComputers as getCustomerComputers,
    getRdp,
} from '../../../api';

import {
    LogContext,
} from '../../Context';


const Process = () => {
    const [expandedPanels, setExpandedPanels] = React.useState([]);
    const [loading, setLoading] = React.useState([]);

    const { token, isAuthenticationValid } = React.useContext(LogContext);

    const handleChange = (index) => (e, isExpanded) => {
        setExpandedPanels(isExpanded
            ? _.uniq([...expandedPanels, index])
            : _.filter(expandedPanels, pan => pan !== index)
        );
    }

    const handleRun = (process) => async () => {
        if (process === 'updateRdpLinks') {
            setLoading([true]);

            // get première page de customer
            const responseCustomers = await fetch(getCustomers('name', 1), {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });

            if (isAuthenticationValid(responseCustomers)) {
                if (responseCustomers.status && responseCustomers.status === 200) {
                    const resultCustomers = await responseCustomers.json();
                    
                    const { last_page: lastPage } = resultCustomers;

                    for (let pageCustomer = 1; pageCustomer <= lastPage; pageCustomer++) {
                        setLoading(prevLoading => ([...prevLoading, true]));

                        fetch(getCustomers('name', pageCustomer), {
                            method: 'GET',
                            headers: {
                                Authorization: `Bearer ${token}`,
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                        }).then((response) => {
                            if (isAuthenticationValid(response)) {
                                if (response.status && response.status === 200) {
                                    response.json().then((resultsCustomer) => {
                                        const { data: customers } = resultsCustomer;

                                        // On récupère les computers de chaque customer
                                        _.map(customers, ({id_customer: identifiantCustomer}) => {
                                            setLoading(prevLoading => ([...prevLoading, true]));

                                            fetch(getCustomerComputers(identifiantCustomer), {
                                                method: 'GET',
                                                headers: {
                                                    Authorization: `Bearer ${token}`,
                                                    Accept: 'application/json',
                                                    'Content-Type': 'application/json',
                                                },
                                            }).then((responseCustomerComputers) => {
                                                if (isAuthenticationValid(responseCustomerComputers)) {
                                                    if (responseCustomerComputers.status && responseCustomerComputers.status === 200) {
                                                        responseCustomerComputers.json().then((resultsCustomerComputers) => {
                                                            // pour chaque computer, on récupère le link
                                                            _.map(resultsCustomerComputers, ({id_computer_rg: identifiantRg}) => {
                                                                setLoading(prevLoading => ([...prevLoading, true]));

                                                                fetch(getRdp(identifiantRg), {
                                                                    method: 'GET',
                                                                    headers: {
                                                                        Accept: 'application/json',
                                                                        'Content-Type': 'application/json',
                                                                        Authorization: `Bearer ${token}`,
                                                                    },
                                                                }).then(() => {
                                                                    setLoading(prevLoading => _.initial(prevLoading));
                                                                })
                                                            })
                                                        });

                                                        setLoading(prevLoading => _.initial(prevLoading));
                                                    }
                                                } else {
                                                    setLoading([]);
                                                }
                                            })
                                        })
                                    });

                                    setLoading(prevLoading => _.initial(prevLoading));
                                }
                            } else {
                                setLoading([]);
                            }
                        })
                    }
                } else {
                    setLoading([]);
                }
            } else {
                setLoading([]);
            }
            
            setLoading(prevLoading => _.initial(prevLoading));
        }
    }

    return (
        <Grid container spacing={8}>
            <Grid item xs={12}>
                <ContentHeader libelle='PROCÉDURES EXCEPTIONNELLES' />
            </Grid>
            <Grid item xs={12}>
                <Backdrop open={!_.isEmpty(loading)} style={{ zIndex: 1 }}>
                    <CircularProgress style={{color: 'white'}}/>
                </Backdrop>
                <UpdateRdpLinks
                    expanded={_.includes(expandedPanels, 0)}
                    onChange={handleChange(0)}
                    onRun={handleRun('updateRdpLinks')}
                />
            </Grid>
        </Grid>
    );
};

export default Process;