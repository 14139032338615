import _ from 'lodash';
import React from 'react';

import {
    Box,
    Typography,
    CircularProgress,
    Button,
    ButtonGroup,
    Snackbar,
} from '@material-ui/core';
import {
    TreeView,
    TreeItem,
    Alert
} from '@material-ui/lab';

import {
    Add,
    Remove,
    Person,
    DeleteOutlined,
    EditOutlined,
    Block,
    ExposureNeg1,
    ExposurePlus1,
    Edit,
    AddToQueue,
    LinkOutlined,
} from '@material-ui/icons';

import { DashboardContext, LogContext } from '../Context';
import {
    counter as getCounter,
    customerComputers,
    incrementCustomerCompteur,
    decrementCustomerCompteur,
    deleteCustomerComputer,
    deleteAgentRg
} from '../../api';
import DialogManageComputer from '../Dialog/DialogManageComputer';
import DialogConfirm from '../Dialog/DialogConfirm';
import LightTooltip from '../LightTooltip';
import DialogAddComputerDashboard from '../Dialog/DialogAddComputerDashboard';

const DashboardComputer = () => {
    const [alert, setAlert] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [computers, setComputers] = React.useState(null);
    const [counter, setCounter] = React.useState(null);
    const [hovered, setHovered] = React.useState();
    const [openModal, setOpenModal] = React.useState();
    const [openConfirm, setOpenConfirm] = React.useState();
    const [openModalAddComputer, setOpenModalAddComputer] = React.useState();

    const { token, isAuthenticationValid } = React.useContext(LogContext);
    const { users, activeCustomer, setReload } = React.useContext(DashboardContext);

    const { id_customer: identifiantCustomer } = activeCustomer || {};

    const { total, used } = counter || {};

    const getComputers = React.useCallback(() => {
        setLoading(true);

        if (!_.isNil(identifiantCustomer) && !_.isNil(users)) {
            fetch(customerComputers(identifiantCustomer), {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                if (isAuthenticationValid(response)) {
                    if (response.status && response.status === 200) {
                        response.json().then((data) => {
                            const unattachedComputers = _.filter(data, d => _.isNil(d.id_computer));

                            if (!_.isEmpty(users)) {
                                const computersUsers = [];
                                
                                //inversion du tableau pour avoir computer avec ses users
                                _.forEach(users, (user) => {
                                    const { computers: userComputers } = user;
                                    _.forEach(userComputers, (computer) => {
                                        const index = _.findIndex(computersUsers, (comp) => comp.id_computer_rg === computer.id_computer_rg);
                                        if (index === -1) {
                                            computersUsers.push({...computer, users: [user]});
                                        } else {
                                            computersUsers[index].users = [
                                                ...computersUsers[index].users,
                                                user
                                            ]
                                        }
                                    });
                                });

                                setComputers([
                                    ...computersUsers,
                                    ..._.filter(
                                        data,
                                        d => (_.isNil(d.id_computer) || _.isNil(_.find(computersUsers, c => c.id_computer === d.id_computer)))
                                    )
                                ]);
                            } else {
                                setComputers([...unattachedComputers]);
                            }

                            setLoading(false);
                        });
                    }
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticationValid, token, users])

    React.useEffect(() => {
        setComputers(null);
        setCounter(null);

        getComputers();
    }, [getComputers]);


    const getCounterCustomer = React.useCallback(/*async*/ () => {
        if (!_.isNil(identifiantCustomer)) {
            // try {

            
           /*await*/  fetch(getCounter(identifiantCustomer), {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                if (isAuthenticationValid(response)) {
                    if (response.status && response.status === 200) {
                        response.json().then((data) => {
                            setCounter(data);
                        });
                    }
                }
            }).catch(e => {
                // console.log('cathc', e);
                // setCounter({total:0, used:0});
            });
//         } catch(e) {
// console.log(e);
//             }
        }
    }, [identifiantCustomer, isAuthenticationValid, token]);

    React.useEffect(() => {
        // try {

            getCounterCustomer();
        // } catch (e){
        //     console.log('cathc useEffect', e);
        // }
    }, [getCounterCustomer, identifiantCustomer]);

    const handleMouseEnter = (identifiant) => () => { setHovered(identifiant) }

    const handleMouseLeave = () => { setHovered(null); }

    const handleSave = () => { setReload(true); }

    const handleOpenModal = (e, idRg) => { e.stopPropagation(); setOpenModal(idRg); }

    const handleCloseModal = () => setOpenModal(null);
    
    const computerSelected = _.find(computers, computer => computer.id_computer_rg === openModal);
    
    const handleOpenConfirm = (e, id) => {
        e.stopPropagation();

        setOpenConfirm(id);
    }

    
    const handleOpenModalAddComputer = () => setOpenModalAddComputer(true);

    const handleCloseModalAddComputer = () => setOpenModalAddComputer(false);

    const handleSubmit = () => { 
        handleCloseModalAddComputer();

        setReload(true);
        setCounter(prevState => ({...prevState, used: prevState?.used + 1}))

    }

    const handleDeleteComputer = () => {
        setLoading(true);
        if (_.isNumber(openConfirm)){
            fetch(deleteCustomerComputer(identifiantCustomer, openConfirm), {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                if (isAuthenticationValid(response)) {
                    if (response.status && response.status === 200) {
                        response.json().then(() => {
                            setReload(true);
                            setLoading(false);
                            setOpenConfirm();
                            setCounter(prevState => ({...prevState, used: prevState?.used - 1}))
                        });
                    }
                }
            });
        } else {
            fetch(deleteAgentRg(openConfirm), {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                if (isAuthenticationValid(response)) {
                    if (response.status && response.status === 200) {
                        response.json().then(() => {
                            setReload(true);
                            setLoading(false);
                            setOpenConfirm();
                        });
                    }
                }
            });
        }
    }

    const handleUpdateCompteur = (increment) => {
        const route = increment 
            ? incrementCustomerCompteur(identifiantCustomer) 
            : decrementCustomerCompteur(identifiantCustomer);

            fetch(route, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then(() => {
                        setCounter(prevState => ({
                            ...prevState,
                            used: increment 
                                ? prevState.used + 1 
                                : prevState.used - 1
                        }))
                    });
                }
            }
        });
    }

    const handleClickCopy = (e, link) => {
        e.stopPropagation();

        navigator.clipboard.writeText(link).then(() => {
            setAlert("Le lien d'accès a bien été copié")
        })
    }

    const handleCloseError = () => setAlert(null);

    return (
        <Box style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
        }}
        >
            <Box style={{ paddingBottom: '1em' }}>
                <Typography
                    variant='h6'
                    style={{
                        borderLeft: '5px solid #0064AE',
                        paddingLeft: '0.5em',
                    }}
                >
                    Liste des postes
                </Typography>
            </Box>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    overflowY: 'auto',
                    marginBottom: '0.5em',
                }}
            >
                {!_.isEmpty(computers)
                   ? (
                    <TreeView
                        defaultCollapseIcon={<Remove />}
                        defaultExpandIcon={<Add />}
                        defaultEndIcon={<Block />}
                        disableSelection
                    >
                        {_.map(computers, (computer) => {
                            const {
                                id_computer: identifiant,
                                id_computer_rg: identifiantRg,
                                users: usersComputer,
                                computer_name: name,
                                rdp_link: link,
                            } = computer;

                            return (
                                <TreeItem
                                    nodeId={identifiantRg}
                                    label={(
                                        <Box
                                            onFocus={handleMouseEnter(identifiant)}
                                            onMouseEnter={handleMouseEnter(identifiant)}
                                            onMouseLeave={handleMouseLeave}
                                            style={{display:'flex', flexDirection:'inherit'}}
                                        >
                                            <Box style={{ flex: 1 }}>
                                                <Typography >
                                                    {identifiantRg}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontSize: '1rem',
                                                        fontWeight: 400,
                                                        lineHight: 1.43,
                                                        color: 'rgba(0, 0, 0, 0.54)',
                                                    }}
                                                >
                                                    {name}
                                                </Typography>
                                            </Box>
                                            <Box style={{display:'flex'}}>
                                                {!_.isNil(link) && (
                                                    <LightTooltip title="Copier le lien d'accès public">
                                                        <Button
                                                            disableRipple
                                                            onClick={(e) => handleClickCopy(e, link)}
                                                            style={{ visibility: hovered === identifiant ? 'visible' : 'hidden' }}
                                                        >
                                                            <LinkOutlined />
                                                        </Button>
                                                    </LightTooltip>
                                                )}
                                                <LightTooltip title="Gérer le poste">
                                                    <Button
                                                        disableRipple
                                                        style={{ visibility: hovered === identifiant ? 'visible' : 'hidden' }}
                                                        onClick={(e) => handleOpenModal(e,identifiantRg)}
                                                        title='Modifier'
                                                    >
                                                        <EditOutlined />
                                                    </Button>
                                                </LightTooltip>
                                                <LightTooltip title="Supprimer le poste">
                                                    <Button
                                                        disableRipple
                                                        style={{ visibility: hovered === identifiant ? 'visible' : 'hidden' }}
                                                        onClick={(e) => handleOpenConfirm(e, _.isNil(identifiant) ? identifiantRg : identifiant)}
                                                    >
                                                        <DeleteOutlined />
                                                    </Button>
                                                </LightTooltip>
                                            </Box>
                                        </Box>
                                    )}
                                >
                                    {_.map(usersComputer, (user) => {
                                        const {
                                            first_name: firstName,
                                            last_name: lastName,
                                            principal_name: principalName,
                                            id_user: identifiantUser,
                                        } = user;

                                        const label = `${_.toUpper(lastName)} ${firstName}`;

                                        return (
                                            <TreeItem
                                                nodeId={identifiantUser}
                                                label={(
                                                    <>
                                                        <Typography>
                                                            {label}
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                fontSize: '1rem',
                                                                fontWeight: 400,
                                                                lineHight: 1.43,
                                                                color: 'rgba(0, 0, 0, 0.54)',
                                                            }}
                                                        >
                                                            {principalName}
                                                        </Typography>
                                                    </>
                                              )}
                                                endIcon={<Person />}
                                            />
                                        );
                                    })}
                                </TreeItem>
                            );
                        })}
                    </TreeView>
                    )
                : 
                  <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        overflowY: 'auto',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {_.isNil(computers) ? <CircularProgress size={24} />: <Typography>Aucun poste</Typography>} 
                </Box>
                }
            </Box>
                <Box>
                    <Button
                        disableRipple
                        onClick={handleOpenModalAddComputer}
                        variant="contained"
                        color='primary'
                        startIcon={<AddToQueue />}
                        size='small'
                    >
                        Ajouter un poste
                    </Button>
                    {!_.isNil(counter) && (
                        <Typography style={{
                            fontSize: '1rem',
                            fontWeight: 400,
                            lineHight: 1.43,
                            color: 'rgba(0, 0, 0, 0.54)',
                            float: 'right',
                        }}
                        >
                            <LightTooltip
                                interactive
                                placement='bottom'
                                leaveDelay={200}
                                disableTouchListener
                                disableFocusListener
                                title={
                                    <ButtonGroup variant='contained' color='primary'> 
                                        <Button 
                                            disabled={used === 0}
                                            onClick={() => handleUpdateCompteur(false)}
                                        >
                                            <ExposureNeg1 />
                                        </Button>
                                        <Button
                                            disabled={used === total}
                                            onClick={() => handleUpdateCompteur(true)}
                                        >
                                            <ExposurePlus1 />
                                        </Button>
                                    </ButtonGroup>
                                }
                            >
                                <span style={{ color: '#1976d2', cursor: 'pointer' }}>
                                    {` ${used} postes déclarés`}
                                    <Edit style={{ marginLeft: 5, fontSize: '1rem' }} />
                                    {' '}
                                </span>
                            </LightTooltip>
                            {` sur ${total} disponibles`}
                        </Typography>
                    )}
                </Box>
            {!_.isNil(openModal) && (
                <DialogManageComputer
                    computer={computerSelected}
                    usersAttached={computerSelected.users}
                    onSave={handleSave}
                    identifiantCustomer={identifiantCustomer}
                    onClose={handleCloseModal}
                />
            )}
            {!_.isNil(openConfirm) && (
                <DialogConfirm
                    text='Êtes-vous sûr de vouloir supprimer ce poste ?'
                    onConfirm={handleDeleteComputer}
                    onCancel={() => setOpenConfirm(null)}
                    loading={loading}
                />
            )}
            {openModalAddComputer && (
                <DialogAddComputerDashboard
                    onClose={handleCloseModalAddComputer}
                    onSubmit={handleSubmit}
                />
            )}
            <Snackbar
                open={!_.isNil(alert)}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleCloseError}
            >
                <Alert
                    severity="success"
                    variant="filled"
                    onClose={handleCloseError}
                >
                    {alert}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default DashboardComputer;
