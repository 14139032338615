import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
    Button,
    Grid,
    TextField,
    MenuItem,
    InputAdornment,
    IconButton,
    CircularProgress,
    Snackbar,
} from '@material-ui/core';
import {
    Send,
    Close,
    MoreHoriz,
    InfoOutlined,
    PersonAdd,
} from '@material-ui/icons';

import UserList from '../Content/Activation/UserList';
import { inviteUser } from '../../api';
import { LogContext } from '../Context';
import LightTooltip from '../LightTooltip';
import { inviteUserByCustomer } from '../../api/routes';
import { Alert } from '@material-ui/lab';

const DialogInviteNewUser = (props) => {
    const { onSend, users, smallButton, identifiantCustomer, identifiantCim } = props;

    const { token, isAuthenticationValid } = React.useContext(LogContext);

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [usersModalOpen, setUsersModalOpen] = React.useState(false);
    const [invite, setInvite] = React.useState({
        first_name: '',
        last_name: '',
        email: '',
        role: false,
        id: _.uniqueId(),
        cim: false,
    })

    const {
        first_name,
        last_name,
        email,
        role,
        cim
    } = invite;

    const optionsRoles = [
        {
            value: true, label: 'Administrateur',
        },
        {
            value: false, label: 'Utilisateur',
        },
    ];

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleUsersModalOpen = () => {
        setUsersModalOpen(true);
    }

    const handleUserSelect = (user) => {
        if (user) {                    
            setInvite({...invite, ...user, cim: true});
            setUsersModalOpen(false);
        }
    }

    const handleUsersModalClose = () => {
        setUsersModalOpen(false);
    }

    const handleChange = (event) => {
        const { target } = event || {};
        const {
            name,
            value
        } = target || {};
        
        setInvite({ ...invite, [name]: value })
    }

    const handleInvite = () => {
        setLoading(true);
        
        const route = identifiantCustomer ? inviteUserByCustomer(identifiantCustomer) : inviteUser();

        if(_.isEmpty(last_name) || _.isEmpty(first_name) || _.isEmpty(email)){
            setError('Tous les champs sont obligatoires.')
            setLoading(false);
        } else {
            fetch(route, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    ...invite,
                    last_name: _.toUpper(last_name),
                    first_name: _.upperFirst(first_name)
                }),
            }).then((response) => {
                if (isAuthenticationValid(response)) {
                    if (response.status && response.status !== 200) {
                        response.json().then((data) => {
                            setError("Une erreur est survenue lors de l'invitation");
                        });
                        
                    } else {
                        if (onSend) onSend();
                    }
                }
    
                setOpen(false);
                setLoading(false);
            })
        }
    }

    const renderFirstNameRow = () => (
        <>
            <Grid item xs={4}>
                <Typography variant='body2'>Prénom</Typography>
            </Grid>
            <Grid item xs={8}>
                <TextField 
                    key='first_name'
                    name='first_name'
                    value={_.upperFirst(first_name)}
                    onChange={handleChange}
                    size='small'
                    fullWidth
                    variant="outlined"
                />
            </Grid>
        </>
    );

    const renderLastNameRow = () => (
        <>
            <Grid item xs={4}>
                <Typography variant='body2'>Nom</Typography>
            </Grid>
            <Grid item xs={8}>
                <TextField 
                    key='last_name'
                    name='last_name'
                    size='small'
                    fullWidth
                    variant="outlined"
                    value={_.toUpper(last_name)}
                    onChange={handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton disableRipple 
                                    size='small'
                                    onClick={handleUsersModalOpen}
                                >
                                    <MoreHoriz />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
        </>
    );

    const renderMailRow = () => (
        <>
            <Grid item xs={4}>
                <Typography variant='body2'>Email</Typography>
            </Grid>
            <Grid item xs={8}>
                <TextField 
                    key='email'
                    name='email'
                    value={email}
                    onChange={handleChange}
                    size='small'
                    fullWidth
                    variant="outlined"
                />
            </Grid>
        </>
    );

    const renderRoleRow = () => (
        <>
            <Grid item xs={4}>
                <Typography variant='body2'>
                    <LightTooltip
                        placement='bottom'
                        title={
                            <>
                                <Typography style={{ whiteSpace: 'pre-wrap' }}>
                                    <b>Utilisateur :</b>
                                    <ul style={{marginTop: 0, listStyleType: '"- "'}}>
                                        <li>peut ajouter un poste,</li>
                                        <li>peut gérer les postes sur lesquels il est rattaché,</li>
                                        <li>peut modifier ses informations personnelles</li>
                                    </ul>
                                </Typography>
                                <br />
                                <Typography style={{ whiteSpace: 'pre-wrap' }}>
                                    <b>Administrateur :</b>
                                    <ul style={{marginTop: 0, listStyleType: '"- "'}}>
                                        <li>peut ajouter un poste,</li>
                                        <li>peut gérer les postes sur lesquels il est rattaché,</li>
                                        <li>peut modifier ses informations personnelles</li>
                                        <li>peut gérer tous les utilisateurs (invitation, modification, suppression, attacher/détacher des postes)</li>
                                        <li>peut gérer tous les postes (suppression, attacher/détacher des utilisateurs)</li>
                                    </ul>
                                </Typography>
                            </>
                        }
                    >
                        <span style={{ color: '#1976d2' }}>
                            {' '}
                            Rôle
                            <InfoOutlined style={{ marginBottom: 5, fontSize: '1rem' }} />
                            {' '}
                        </span>
                    </LightTooltip>
                    </Typography>
            </Grid>
            <Grid item xs={8}>
                <TextField 
                    key='role'
                    name='role'
                    value={role}
                    onChange={handleChange}
                    size='small'
                    select 
                    fullWidth
                    variant="outlined"
                >
                    {_.map(optionsRoles, role => (
                        <MenuItem key={role.value} value={role.value}>
                            {role.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
        </>
    );

    const handleCloseError = () => setError();

    return (
        <>
            {smallButton 
                ? (
                <Button
                    disableRipple
                    onClick={handleOpen}
                    variant="contained"
                    color='primary'
                    startIcon={<PersonAdd />}
                    size='small'
                >
                    Ajouter un utilisateur
                </Button>
                )
                : (
                <Button
                    disableRipple
                    onClick={handleOpen}
                    variant="contained"
                    color='primary'
                    startIcon={<Send />}
                >
                    Inviter un nouvel utilisateur
                </Button>
                )
            }
            <Dialog onClose={handleClose} open={open}>
                <DialogTitle onClose={handleClose}>
                    Inviter un nouvel utilisateur
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom paragraph>
                        Après validation de ce formulaire, un email sera
                        envoyé à l’utilisateur, avec un lien d’activation.
                    </Typography>
                    <Typography gutterBottom paragraph>
                        Il devra créer son compte et choisir son mot de passe.
                    </Typography>
                    <Typography gutterBottom paragraph>
                        Il devra ensuite déclarer un nouveau poste
                        <LightTooltip
                            placement='bottom'
                            title='Le poste qui sera contrôlé à distance par le poste client'
                        >
                            <span style={{color: "#1976d2"}}> « Hôte »<InfoOutlined style={{marginBottom: 5, fontSize: '1rem'}} /> </span>
                        </LightTooltip>
                        ou associer son compte à l’un des postes
                        <LightTooltip
                            placement='bottom'
                            title='Le poste qui sera contrôlé à distance par le poste client'
                        >
                            <span style={{color: "#1976d2"}}> « Hôte »<InfoOutlined style={{marginBottom: 5, fontSize: '1rem'}} /> </span>
                        </LightTooltip>
                        déjà existant depuis le menu « Mes postes ».
                    </Typography>
                    <Grid container alignItems='center' spacing={2}>
                        {renderLastNameRow()}
                        {renderFirstNameRow()}
                        {renderMailRow()}
                        {renderRoleRow()}
                        { usersModalOpen && (
                            <UserList
                                onSelect={handleUserSelect} 
                                onClose={handleUsersModalClose}
                                selected={cim ? [email, ..._.map(users, u => u.principal_name)] : [..._.map(users, u => u.principal_name)]}
                                identifiantCim={identifiantCim}
                            />
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        startIcon={<Close />}
                        disableRipple
                        variant="outlined"
                        onClick={handleClose}
                        color="primary"
                        disabled={loading}
                    >
                        Fermer
                    </Button>
                    <Button
                        disableRipple
                        variant="contained"
                        onClick={handleInvite}
                        color="primary"
                        startIcon={loading
                            ? <CircularProgress size={20} color='white' />
                            : <Send />
                        }
                        disabled={loading}
                    >
                        Envoyer l&apos;invitation
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={!_.isNil(error)}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleCloseError}
            >
                <Alert
                    severity="error"
                    variant="filled"
                    onClose={handleCloseError}
                >
                    {error}
                </Alert>
            </Snackbar>
        </>
    );
};

DialogInviteNewUser.propTypes = {
    onSend: PropTypes.func,
    users: PropTypes.array,
    smallButton: PropTypes.bool,
    identifiantCustomer: PropTypes.number,
}

DialogInviteNewUser.defaultProps = {
    smallButton: false,
    identifiantCustomer: null,
}

export default DialogInviteNewUser;
