import _ from 'lodash';
import React from 'react';

import {
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    Link,
} from '@material-ui/core';
import { ExpandMore, InfoOutlined } from '@material-ui/icons';

import deleteComputer from '../../../assets/images/deleteComputer.png';
import LightTooltip from '../../LightTooltip';

const SwapComputer = (props) => {
    const {
        onAddComputerClick,
        ...rest
    } = props;

    const [illustrationVisible, setIllustrationVisible] = React.useState([]);

    const handleAddComputerClick = () => {
        setIllustrationVisible([]);
        if (onAddComputerClick) {
            onAddComputerClick();
        }
    }
    const handleIllustrationVisible = (index) => {
        if (_.includes(illustrationVisible, index)) {
            const tmp = _.filter(illustrationVisible, index);
            setIllustrationVisible(tmp);
        } else {
            const tmp = _.union(illustrationVisible, [index]);
            setIllustrationVisible(tmp);
        }
    };

    return (
        <ExpansionPanel {...rest}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant='h6'>... Changer de poste</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <ol>
                    <li>
                        <Typography>Aller dans l&apos;onglet Mes postes</Typography>
                    </li>
                    <li>
                        <Typography>
                            Sélectionner le poste dont vous n'avez plus besoin
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            Cliquer sur le lien &quot;Supprimer ce poste&quot;
                            (
                            <Link style={{ cursor: 'pointer' }} onClick={() => handleIllustrationVisible(1)}>
                                {_.includes(illustrationVisible, 1)
                                    ? "Masquer l'illustration"
                                    : "Afficher l'illustration"}
                            </Link>
                            )
                        </Typography>
                        {_.includes(illustrationVisible, 1) && <img src={deleteComputer} alt='delete-computer' />}
                    </li>
                    <li>
                        <Typography>
                            Confirmer la suppression
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            Suivre les étapes suivante :
                        </Typography>
                        <Typography>
                            <Link
                                style={{ cursor: 'pointer' }}
                                onClick={handleAddComputerClick}
                            >
                                Comment faire pour ajouter un nouveau poste
                                <LightTooltip
                                    placement='bottom'
                                    title='Le poste qui sera contrôlé à distance par le poste client'
                                >
                                    <span style={{ color: '#1976d2' }}>
                                        {' '}
                                        « Hôte »
                                        <InfoOutlined style={{ marginBottom: 5, fontSize: '1rem' }} />
                                        {' '}
                                    </span>
                                </LightTooltip>
                            </Link>
                        </Typography>
                    </li>
                </ol>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default SwapComputer;
