import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
    Dialog,
    DialogContent,
    Typography,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    CircularProgress,
} from '@material-ui/core';

const DialogChoiceCustomer = (props) => {
    const {
        items,
        onSelect,
        onClose,
        loading,
    } = props;

    const handleClickRow = (index) => { if (onSelect) { onSelect(items[index]); } };

    const handleClose = () => { if (onClose) { onClose(); } };

    const renderTable = () => (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Typography variant='body2'>Sélectionner la collectivité sur laquelle vous voulez vous connecter</Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                { loading
                    ? (
                        <TableRow>
                            <div
                                style={{
                                    height: 200,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CircularProgress size={24} />
                            </div>
                        </TableRow>
                    ) : _.map(items, (item, index) => (
                        <TableRow key={`TableRowCustomer.${item.identifiant}`} onClick={() => handleClickRow(index)}>
                            <TableCell>
                                <Typography>{item.nom}</Typography>
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );

    return (
        <Dialog
            open
            style={{ textAlign: 'center' }}
            maxWidth='md'
            {...!loading && { onClose: handleClose }}
        >
            <DialogContent>
                {renderTable()}
            </DialogContent>
        </Dialog>
    );
};

DialogChoiceCustomer.propTypes = {
    items: PropTypes.array,
    onSelect: PropTypes.func,
    onClose: PropTypes.func,
    loading: PropTypes.bool,
};

DialogChoiceCustomer.defaultProps = {
    loading: false,
};

export default DialogChoiceCustomer;
