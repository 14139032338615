import React from 'react';

const context = React.createContext({
    reload: false,
    customers: null,
    users: null,
    activeCustomer: null,
    setReload: () => {},
    setActiveCustomer: () => {},
    setCustomers: () => {},
    setUsers: () => {},
});

export default context;
