import React from 'react';

import PropTypes from 'prop-types';

import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';

const DisconnectMessage = (props) => {
    const { manually } = props;

    return (
        <Alert
            style={{
                border: '1px solid rgba(30, 70, 32, 0.2)',
            }}
        >
            {
                manually
                    ? (
                        <>
                            <Typography>Vous vous êtes déconnecté(e).</Typography>
                            <Typography>Vous pouvez vous connecter à nouveau.</Typography>
                            <Typography>Pour des raisons de sécurité, veuillez fermer votre navigateur.</Typography>
                        </>
                    ) : (
                        <>
                            <Typography>Vous avez été déconnecté(e).</Typography>
                            <Typography>
                                Après une longue période d&apos;inactivité, la session est
                                automatiquement terminée pour des raisons de sécurité.
                            </Typography>
                        </>
                    )
            }
        </Alert>
    );
};

DisconnectMessage.propTypes = {
    manually: PropTypes.bool,
};

export default DisconnectMessage;
