import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    DialogTitle,
    Grid,
    TextField,
    Snackbar
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import { LogContext, DashboardContext } from '../Context';
import { Autocomplete, Alert } from '@material-ui/lab';
import { createCustomerComputer } from '../../api';

const DialogAddComputerDashboard = (props) => {
    const {
        onClose,
        onSubmit
    } = props;
    
    const [nodeRg, setNodeRg] = React.useState('');
    const [name, setName] = React.useState('');
    const [usersSelected, setUsersSelected] = React.useState([])
    const [error, setError] = React.useState();

    const { token, isAuthenticationValid } = React.useContext(LogContext);
    
    const { activeCustomer, users } = React.useContext(DashboardContext);
    
    const { id_customer: identifiantCustomer } = activeCustomer || {};

    
    const handleChangeNode = ({ target }) => {
        const { value } = target;

        setNodeRg(value);
    }
    
    const handleChangeName = ({ target }) => {
        const { value } = target;

        setName(value);
    }

    const handleChangeAutocomplete = (e, u) => setUsersSelected(u);

    
    const handleClose = () => {
        if(onClose) { 
            onClose();
        }
    }

    const handleSubmit = () => {
        
        // setLoading(true);
        if( _.isEmpty(nodeRg) || _.isEmpty(name)){
            setError("Le nom et l'identifiant RG du poste sont obligatoire")
            // setLoading(false);
        }
        
        else if(!_.isEmpty(usersSelected)){
            _.forEach(usersSelected, u => {
                fetch(createCustomerComputer(identifiantCustomer), {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        id_computer_rg: nodeRg,
                        computer_name: name,
                        id_user: u.id_user,
        
                    }),
                }).then((response) => {
                    if (isAuthenticationValid(response)) {
                        if (response.status && response.status === 200) {
                            response.json().then((data) => {
                                // setLoading(false);
        
                                if(onSubmit){
                                    onSubmit();
                                }
                            })
                        }
                    }
                });
            })
        } else {
            fetch(createCustomerComputer(identifiantCustomer), {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    id_computer_rg: nodeRg,
                    computer_name: name,
                }),
            }).then((response) => {
                if (isAuthenticationValid(response)) {
                    if (response.status && response.status === 200) {
                        response.json().then((data) => {
                            // setLoading(false);

                            if(onSubmit){
                                onSubmit();
                            }
                        })
                    }
                }
            });
        }
    }

    const handleCloseError = () => setError();

    return (
        <>
            <Dialog open maxWidth='md' fullWidth>
                <DialogTitle>
                    Ajouter un poste
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3} style={{ alignItems: 'center' }}>
                        <Grid item xs={4}>
                            <Typography variant='body2'>Identifiant node RG System</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField 
                                key='node'
                                name='node'
                                value={nodeRg}
                                onChange={handleChangeNode}
                                size='small'
                                fullWidth
                                variant="outlined"
                                />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='body2'>Nom du poste</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField 
                                key='name'
                                name='name'
                                value={name}
                                onChange={handleChangeName}
                                size='small'
                                fullWidth
                                variant="outlined"
                                />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='body2'>Utilisateurs déjà créé à rattacher</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Autocomplete
                                options={users}
                                getOptionLabel={(option) => _.toUpper(option.last_name)+' '+option.first_name}
                                renderInput={(params) => <TextField {...params} size='small' fullWidth variant="outlined" />}
                                multiple
                                onChange={handleChangeAutocomplete}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        disableRipple
                        variant="outlined"
                        color="primary"
                        startIcon={<Close />}
                        onClick={handleClose}
                    >
                        Annuler
                    </Button>
                    <Button
                        disableRipple
                        variant="contained"
                        onClick={handleSubmit}
                        color="primary"
                        startIcon={<Check />}
                    >
                        Ajouter
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={!_.isNil(error)}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleCloseError}
            >
                <Alert
                    severity="error"
                    variant="filled"
                    onClose={handleCloseError}
                >
                    {error}
                </Alert>
            </Snackbar>
        </>
    );
};

DialogAddComputerDashboard.propTypes = {
    text: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
}

export default DialogAddComputerDashboard;
