import React from 'react';

const context = React.createContext({
    token: '',
    setToken: () => {},
    isAuthenticationValid: () => {},
    disconnect: () => {},
});

export default context;
