import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
    Grid,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    CircularProgress,
} from '@material-ui/core';

import LogContext from '../../Context/LogContext';
import DialogDownloadTool from '../../Dialog/DialogDownloadTool';
import DialogManageComputer from '../../Dialog/DialogManageComputer';
import DialogNewComputer from '../../Dialog/DialogNewComputer';
import DialogConfirm from '../../Dialog/DialogConfirm';
import {
    deleteComputer,
    customerComputers,
    computerUsers,
} from '../../../api';

const ComputersTab = (props) => {
    const { onComputerDelete } = props;

    const { token, isAuthenticationValid } = React.useContext(LogContext);

    const [selectedRow, setSelectedRow] = React.useState(null);
    const [computers, setComputers] = React.useState([]);
    const [userComputers, setUserComputers] = React.useState([]);
    const [openModal, setOpenModal] = React.useState();
    const [loading, setLoading] = React.useState(['customerComputers', 'computerUsers']);
    const [openConfirm, setOpenConfirm] = React.useState();     

    const getComputers = React.useCallback(() => {
        setLoading((prevLoading) => _.uniq([...prevLoading, 'customerComputers']));

        fetch(customerComputers(), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((data) => {
                        setComputers(data);

                        setLoading((prevLoading) => ([..._.filter(prevLoading, (load) => load !== 'customerComputers')]));
                    });
                }
            }
        });
    }, [isAuthenticationValid, token]);

    const getUsers = React.useCallback(() => {
        setLoading((prevLoading) => _.uniq([...prevLoading, 'computerUsers']));

        const pendingPromises = [];

        _.map(computers, (computer) => {
            const { id_computer_rg: identifiantRg } = computer;

            if (!_.find(userComputers, (uc) => uc.identifiantRg === identifiantRg)) {
                pendingPromises.push(fetch(computerUsers(identifiantRg), {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    if (isAuthenticationValid(response)) {
                        if (response.status && response.status === 200) {
                            response.json().then((data) => {
                                setUserComputers((state) => {
                                    const index = _.findIndex(state, (uc) => uc.identifiantRg === identifiantRg);

                                    if (index >= 0) {
                                        const copied = [...state];

                                        copied[index].users = data;

                                        return copied;
                                    }

                                    return ([
                                        ...state,
                                        {
                                            identifiantRg,
                                            users: data,
                                        },
                                    ]);
                                });
                            });
                        }
                    }
                }));
            }
        });

        Promise.all(pendingPromises).then(() => {
            setLoading((prevLoading) => ([..._.filter(prevLoading, (load) => load !== 'computerUsers')]));
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [computers, token]);

    React.useEffect(() => {
        getComputers();
    }, [getComputers]);

    React.useEffect(() => {
        getUsers();
    }, [getUsers]);

    const handleClickRow = (index) => setSelectedRow(index);

    const handleDialogConfirm = (computer) => () => handleClickDelete(computer);
    const handleDialogCancel = () => setOpenConfirm();

    const handleClickDelete = (index) => {
        setLoading((prevLoading) => _.uniq([...prevLoading, 'deleteUserComputer']));

        fetch(deleteComputer(index), {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body:JSON.stringify({
                all: 'users',
            })
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then(() => {
                        setSelectedRow(null);

                        const computersTmp = { ...computers };

                        delete computersTmp[index];

                        setComputers(computersTmp);

                        
                        if (onComputerDelete) onComputerDelete();

                    });
                }
            }
        }).finally(() => { 
            setLoading((prevLoading) => ([..._.filter(prevLoading, (load) => load !== 'deleteUserComputer')]));
            setOpenConfirm();
        });
    };

    const handleSave = () => { getUsers(); };

    const handleClose = () => { getComputers(); };

    const handleOpenModal = (user) => setOpenModal(user);

    const handleCloseModal = () => setOpenModal(null);

    if (_.includes(loading, 'computerUsers') || _.includes(loading, 'customerComputers')) {
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <CircularProgress size={24} />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography variant='body2'>Nom des postes</Typography></TableCell>
                                <TableCell><Typography variant='body2'>Rattachements</Typography></TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!_.isEmpty(computers)
                                ? _.map(computers, (computer) => {
                                    const { computer_name: name, id_computer: identifiant, id_computer_rg: identifiantRg } = computer;
                                    const userComputer = _.find(
                                        userComputers,
                                        (uc) => uc.identifiantRg === identifiantRg,
                                    );

                                    const { users } = userComputer || {};

                                    const nombreUsers = _.size(users);

                                    return (
                                        <TableRow
                                            {...!_.isNil(nombreUsers) && nombreUsers !== 0 ? { } : { className: 'unattached' }}
                                            identifiantRg={`Computer${name}`}
                                            onClick={() => handleClickRow(identifiant)}
                                            selected={selectedRow === identifiant}
                                        >
                                            <TableCell>
                                                <Typography>{name}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    {nombreUsers === 0 ? 'aucun' : nombreUsers}
                                                    {' '}
                                                    {nombreUsers > 1 ? 'utilisateurs' : 'utilisateur' }
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                 <Button
                                                    disableRipple
                                                    onClick={() => handleOpenModal(computer)}
                                                    color='primary'
                                                    style={{ visibility: !_.isNil(selectedRow) && selectedRow === identifiant ? 'visible' : 'hidden' }}
                                                >
                                                    Gérer
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <DialogDownloadTool
                                                    libellePoste={name}
                                                    identifiantRg={identifiantRg}
                                                    buttonVisible={
                                                        !_.isNil(selectedRow) && selectedRow === identifiant
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    disableRipple
                                                    color='secondary'
                                                    style={{ visibility: !_.isNil(selectedRow) && selectedRow === identifiant ? 'visible' : 'hidden' }}
                                                    onClick={() => setOpenConfirm(identifiant)}
                                                >
                                                    Supprimer ce poste
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                                : (
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Typography>
                                                Aucun poste déclaré
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                        </TableBody>
                    </Table>
                    {!_.isNil(openModal) && (
                        <DialogManageComputer
                            computer={openModal}
                            usersAttached={_.find(
                                userComputers,
                                (uc) => uc.identifiantRg === openModal.id_computer_rg,
                            ).users}
                            onSave={handleSave}
                            onClose={handleCloseModal}
                        />
                    )}
                    {openConfirm && (
                        <DialogConfirm
                            text={
                                <>
                                    Êtes-vous sur de vouloir supprimer ce poste définitivement ?
                                    <br />
                                    Plus aucun utilisateur n'y sera rattaché.
                                </>
                            }
                            onConfirm={handleDialogConfirm(openConfirm)}
                            onCancel={handleDialogCancel}
                            loading={!_.isEmpty(loading)}
                        />
                    )}
                </Grid>
                <Grid item>
                    <DialogNewComputer onlyDownload onClose={handleClose} />
                </Grid>
            </Grid>
        </Grid>
    );
};

ComputersTab.propTypes = {
    onComputerDelete: PropTypes.func,
};

export default ComputersTab;
