import React from 'react';
import ReactDOM from 'react-dom';

import {
    BrowserRouter,
    Route,
} from 'react-router-dom';

import {
    createMuiTheme,
    ThemeProvider,
} from '@material-ui/core/styles';

import Application from './components/Application/Application';

import * as serviceWorker from './serviceWorker';

import './index.css';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1070bd',
        },
        secondary: {
            main: '#C62828',
        },
    },
    overrides: {
        MuiTableRow: {
            root: {
                cursor: 'pointer',
                '&$selected': {
                    backgroundColor: '#F2F2F2',
                },
                '&$selected&:hover': {
                    backgroundColor: '#F2F2F2',
                },
            },
        },
        MuiTab: {
            root: {
                textTransform: 'none',
            },
        },
        MuiTableCell: {
            stickyHeader: {
                backgroundColor: 'white',
            },
        },
        MuiButton: {
            contained: {
                '&:disabled': {
                    color: '#fff',
                    backgroundColor: '#0064AE',
                    opacity: 0.6,
                },
            },
        },
        MuiTreeItem: {
            root: {
                '&:not(:last-child)': { paddingBottom: '0.5em' },
            },
            group: {
                marginLeft: 30,
            },
            iconContainer: {
                marginRight: 8,
            },
        },
    },
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Route
                path='/'
                render={() => <Application />}
            />
        </BrowserRouter>
    </ThemeProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
