import React from 'react';

import PropTypes from 'prop-types';

import Installation from './Installation';
import Invitation from './Invitation';

const Activation = (props) => {
    const { onEnd } = props;

    const [activeStep, setActiveStep] = React.useState(0);

    const handlePrevious = () => setActiveStep(activeStep - 1);

    const handleNext = () => {
        if (activeStep === 1 && onEnd) {
            onEnd();
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    return (
        <>
            {activeStep === 0 && <Invitation onPrevious={handlePrevious} onNext={handleNext} />}
            {activeStep === 1 && <Installation onNext={handleNext} />}
        </>
    );
};

Activation.propTypes = {
    onEnd: PropTypes.func,
};

export default Activation;
