import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import {
    TextField,
    Button,
    Hidden,
    Typography,
    CircularProgress,
    Snackbar,
    Link,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { LogContext } from '../Context';

import DialogChoiceCustomer from './DialogChoiceCustomer';

import background from '../../assets/images/background-auth.png';
import blue from '../../assets/images/blue-background.png';
import signature from '../../assets/images/signature.png';
import mpadfull from '../../assets/images/mpad-full.png';
import {
    token, authentification, counter,
} from '../../api';
import DisconnectMessage from './DisconnectMessage';

const Authentification = (props) => {
    const {
        onAuth,
        collectivites,
    } = props;

    const { setToken, isAuthenticationValid } = React.useContext(LogContext);

    const [login, setLogin] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [tokenTechnique, setTokenTechnique] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState();

    const { message: errorMessage } = error || {};

    const auth = JSON.parse(sessionStorage.getItem('auth'));
    const { manuallyDisconnected } = auth || {};

    const handleCloseError = () => setError(null);

    const handleLoginChange = ({ target }) => {
        const { value } = target;

        setLogin(value);
    };

    const handlePasswordChange = ({ target }) => {
        const { value } = target;

        setPassword(value);
    };

    const handleFirst = (log, first, tok, admin = false) => {
        if (admin) {
            onAuth(log, null, false, tok, true);

            setToken(tok);
        } else {
            fetch(
                counter(),
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${tok}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                },
            ).then((response) => {
                if (isAuthenticationValid(response)) {
                    if (response.status && response.status === 200) {
                        response.json().then((data) => {
                            const { total } = data;

                            setToken(tok);

                            if (first) {
                                if (total > 1) {
                                    onAuth(log, collectivites, true, tok);
                                } else {
                                    onAuth(log, collectivites, false, tok);
                                }
                            } else {
                                onAuth(log, collectivites, false, tok);
                            }
                        });
                    }
                }
            });
        }
    };

    const handleClickCollectivite = ({ identifiant }) => {
        setOpen(false);

        const host = window.location.host + window.location.pathname; 

        fetch(token(identifiant, login, host),
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${tokenTechnique}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((data) => {
                        const { token: tok, first } = data;

                        if (!_.isNil(tok) && tok !== 'token') {
                            setToken(tok);

                            handleFirst(login, first, tok);
                        }

                        setLoading(false);
                    });
                } else {
                    response.json().then((data) => {
                        const { error: err } = data || {};

                        setError(err);
                    });

                    setLoading(false);
                }
            }
        });
    };

    const handleButtonClick = () => {
        setLoading(true);

        fetch(authentification(), {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                login,
                password,
                host: window.location.host + window.location.pathname,
            }),
        }).then((response) => {
            if (response.status && response.status === 200) {
                setError(null);
                response.json().then((data) => {
                    const {
                        token: tok,
                        first,
                        collectivites: coll,
                        admin,
                    } = data;

                    if (!_.isNil(tok) && tok !== 'token' && !_.isNil(coll)) {
                        setTokenTechnique(tok);
                        onAuth(login, coll);
                        setOpen(true);
                    } else {
                        handleFirst(login, first, tok, admin);
                    }
                });
            } else {
                response.json().then((data) => {
                    const { error: err } = data || {};

                    setError(err);
                });
                setLoading(false);
            }
        });
    };

    const handleKeyDown = ({ key }) => {
        if (_.isEqual(key, 'Enter')) {
            handleButtonClick();
        }
    };

    const renderDisconnectMessage = () => !_.isNil(manuallyDisconnected) && (
        <DisconnectMessage manually={manuallyDisconnected} />
    );

    return (
        <>
            <Hidden
                only={[
                    'xs',
                    'sm',
                    'md',
                ]}
            >
                <div style={{
                    background: `white url(${blue}) right no-repeat`,
                    backgroundSize: '100% 100%',
                }}
                >
                    <div style={{
                        background: `white url(${background})`,
                        width: 1313,
                        margin: 'auto',
                        minHeight: '937px',
                        display: 'block',
                    }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: 863,
                                background: `white url(${signature}) center no-repeat`,
                                width: 1313,
                                height: 70,
                                margin: 'auto',
                                borderTop: '1px solid #dfdfdf',
                            }}
                        />
                        <div
                            style={{
                                float: 'right',
                                marginTop: 355,
                                marginRight: 100,
                                width: 275,
                            }}
                        >
                            <Typography
                                style={{
                                    color: '#006495',
                                    marginBottom: 30,
                                }}
                            >
                                Bienvenue, veuillez vous authentifier
                            </Typography>
                            <form>
                                <TextField
                                    className='authentificationField'
                                    label='Identifiant'
                                    placeholder='prenom.nom@cloud-jvs.local'
                                    size='small'
                                    fullWidth
                                    variant="outlined"
                                    autoComplete='username'
                                    value={login}
                                    onChange={handleLoginChange}
                                    onKeyDown={handleKeyDown}
                                    inputProps={{
                                        style: {
                                            textAlign: 'center',
                                        },
                                    }}
                                    style={{
                                        marginBottom: 10,
                                    }}
                                />
                                <TextField
                                    className='authentificationField'
                                    label='Mot de passe'
                                    size='small'
                                    fullWidth
                                    autoComplete='current-password'
                                    variant="outlined"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    onKeyDown={handleKeyDown}
                                    type='password'
                                    inputProps={{
                                        style: {
                                            textAlign: 'center',
                                        },
                                    }}
                                    style={{
                                        textAlign: 'center',
                                        marginBottom: 40,
                                    }}
                                />
                                <Button
                                    disableRipple
                                    variant="contained"
                                    color="primary"
                                    onClick={handleButtonClick}
                                    fullWidth
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Se connecter'}
                                </Button>
                                <Typography
                                    style={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '1em',
                                    }}
                                >
                                    <Link href='https://password.jvsonline.fr/password/forgot?service=https%3A//emu-hc.jvsonline.fr/' target='_blank'>Mot de passe perdu</Link>
                                </Typography>
                                {renderDisconnectMessage()}
                            </form>
                        </div>
                    </div>
                </div>
            </Hidden>
            <Hidden
                only={['lg', 'xl']}
            >
                <div
                    style={{
                        height: 'calc(100% - 60px)',
                        width: '100%',
                        paddingTop: '1em',
                    }}
                >
                    <div
                        style={{
                            background: `white url(${mpadfull}) no-repeat`,
                            backgroundSize: '100%',
                            width: 275,
                            height: 107,
                            margin: '0 auto 1.5em auto',
                        }}
                    />
                    <div
                        style={{
                            width: 275,
                            margin: 'auto',
                        }}
                    >
                        <Typography
                            style={{
                                color: '#006495',
                                marginBottom: '1em',
                            }}
                        >
                            Bienvenue, veuillez vous authentifier
                        </Typography>
                        <form>
                            <TextField
                                className='authentificationField'
                                size='small'
                                fullWidth
                                variant="outlined"
                                autoComplete='username'
                                value={login}
                                onChange={handleLoginChange}
                                label='Identifiant'
                                placeholder='prenom.nom'
                                inputProps={{
                                    style: {
                                        textAlign: 'center',
                                    },
                                }}
                                style={{
                                    marginBottom: 10,
                                }}
                            />
                            <TextField
                                className='authentificationField'
                                size='small'
                                fullWidth
                                autoComplete='current-password'
                                variant="outlined"
                                value={password}
                                onChange={handlePasswordChange}
                                type='password'
                                inputProps={{
                                    style: {
                                        textAlign: 'center',
                                    },
                                }}
                                label='Mot de passe'
                                style={{
                                    marginBottom: 40,
                                }}
                            />
                            <Button
                                disableRipple
                                variant="contained"
                                color="primary"
                                onClick={handleButtonClick}
                                fullWidth
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Se connecter'}
                            </Button>
                            <Typography
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '1em',
                                }}
                            >
                                <Link href='https://password.jvsonline.fr/password/forgot?service=https%3A//emu-hc.jvsonline.fr/' target='_blank'>Mot de passe perdu</Link>
                            </Typography>
                            { renderDisconnectMessage() }
                        </form>
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        background: `white url(${signature}) center no-repeat`,
                        width: '100%',
                        height: 70,
                        margin: 'auto',
                        borderTop: '1px solid #dfdfdf',
                    }}
                />
            </Hidden>
            {open
                && (
                    <DialogChoiceCustomer items={collectivites} onSelect={handleClickCollectivite} />
                )}
            <Snackbar
                open={!_.isNil(error)}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleCloseError}
            >
                <Alert
                    severity="error"
                    variant="filled"
                    onClose={handleCloseError}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

Authentification.propTypes = {
    onAuth: PropTypes.func,
    collectivites: PropTypes.array,
};

export default Authentification;
