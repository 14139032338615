import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
    Button,
    Tabs,
    Tab,
    Container,
    List,
    ListItem,
    ListItemText,
    Link,
    CircularProgress,
} from '@material-ui/core';
import {
    Check,
    AddToQueue,
    Close,
    InfoOutlined,
} from '@material-ui/icons';

import LogContext from '../Context/LogContext';
import {
    customerComputers,
    createUserComputer,
} from '../../api';
import setup from '../../assets/setup/Setup.exe';
import LightTooltip from '../LightTooltip';

const DialogNewComputer = (props) => {
    const {
        onSubmit,
        attachedComputers,
        onlyDownload,
        onClose,
    } = props;

    const { token, isAuthenticationValid } = React.useContext(LogContext);

    const [
        open,
        setOpen,
    ] = React.useState(false);

    const [
        loading,
        setLoading,
    ] = React.useState(false);

    const [
        activeTab,
        setActiveTab,
    ] = React.useState(0);

    const [
        selectedIndex,
        setSelectedIndex,
    ] = React.useState(0);

    const [
        computersAvailable,
        setComputersAvailable,
    ] = React.useState([]);

    const [
        linkClicked,
        setLinkClicked,
    ] = React.useState(false);

    React.useEffect(() => {
        if (open && !onlyDownload) {
            fetch(customerComputers(), {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                if (isAuthenticationValid(response)) {
                    if (response.status && response.status === 200) {
                        response.json().then((data) => {
                            if (!_.isEmpty(attachedComputers)) {
                                const availableComputers = _.filter(
                                    data,
                                    d => !_.find(attachedComputers,ac => ac.id_computer===d.id_computer)
                                );

                                setComputersAvailable(availableComputers);
                            } else {
                                setComputersAvailable(data);
                            }
                        });
                    }
                }
            });

        }
    }, [attachedComputers, isAuthenticationValid, token, open, onlyDownload]);

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setActiveTab(0);
        setOpen(false);

        if(onClose && linkClicked) onClose();
    };

    const handleChangeTab = (e, indice) => setActiveTab(indice);

    const handleListItemClick = index => setSelectedIndex(index);

    const handleSubmit = () => {
        setLoading(true);

        fetch(createUserComputer(), {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                id_computer: computersAvailable[selectedIndex].id_computer,
            }),
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    setLoading(false);
                    response.json().then((data) => {
                        if (onSubmit) {
                            onSubmit(data);
                        }
                        handleClose();
                    });
                } else {
                    setLoading(false);
                }
            }
        });
    };

    const handleLinkClick = () => setLinkClicked(true)

    const renderNewComputer = () => (
        <>
            <Typography gutterBottom paragraph>
                Pour déclarer l’ordinateur que vous utilisez
                actuellement comme ordinateur
                <LightTooltip
                    placement='bottom'
                    title='Le poste qui sera contrôlé à distance par le poste client'
                >
                    <span style={{color: "#1976d2"}}> « Hôte »<InfoOutlined style={{marginBottom: 5, fontSize: '1rem'}} /></span>
                </LightTooltip>
                , suivez ces instructions :
            </Typography>
            <Typography gutterBottom paragraph>
                <Link href={setup} download onClick={handleLinkClick}>Téléchargez l’outil « Hôte de connexion » sur votre ordinateur </Link>
            </Typography>
            <ul>
                <Typography gutterBottom>
                    <li>
                        Une fois le téléchargement de l’outil effectué, exécutez le sur votre ordinateur
                    </li>
                </Typography>
                <Typography gutterBottom>
                    <li>
                        Durant le processus d’installation, vous devrez saisir votre adresse email et votre mot de passe
                    </li>
                </Typography>
                <Typography gutterBottom>
                    <li>
                        Une fois l’installation terminée, veuillez vous connectez à cette page depuis un
                        autre ordinateur pour prendre le contrôle à distance de ce poste
                        <LightTooltip
                            placement='bottom'
                            title='Le poste qui sera contrôlé à distance par le poste client'
                        >
                            <span style={{color: "#1976d2"}}> « Hôte »<InfoOutlined style={{marginBottom: 5, fontSize: '1rem'}} /> </span>
                        </LightTooltip>
                    </li>
                </Typography>
            </ul>
        </>
    );

    const renderComputerAlreadyExist = () => (
        <>
            <Typography gutterBottom paragraph>
                Sélectionnez un poste
                <LightTooltip
                    placement='bottom'
                    title='Le poste qui sera contrôlé à distance par le poste client'
                >
                    <span style={{color: "#1976d2"}}> « Hôte »<InfoOutlined style={{marginBottom: 5, fontSize: '1rem'}} /> </span>
                </LightTooltip>
                déjà déclaré que vous souhaitez rattacher à votre compte utilisateur
            </Typography>
            <List
                disablePadding
                style={{
                    overflow: 'auto',
                    maxHeight: '100%',
                    height: 250,
                    width: 300,
                }}
            >
                {_.map(computersAvailable, (pc, index) => {
                    const { computer_name: name } = pc;

                    return (
                        <ListItem
                            key={`ListItemComputer-${name}`}
                            button
                            selected={selectedIndex === index}
                            onClick={() => handleListItemClick(index)}
                            disableRipple
                        >
                            <ListItemText primary={name} />
                        </ListItem>
                    );
                })}
            </List>
        </>

    );

    return (
        <>
            <Button
                disableRipple
                onClick={handleOpen}
                variant="contained"
                color='primary'
                startIcon={<AddToQueue />}
            >
                Ajouter un {onlyDownload && ' nouveau ' } poste
            </Button>
            <Dialog onClose={handleClose} open={open} maxWidth='sm'>
                <DialogTitle onClose={handleClose} style={{ textAlign: 'center' }}>
                    Ajouter un {onlyDownload && ' nouveau ' } poste
                </DialogTitle>
                <DialogContent
                    dividers
                    style={{
                        ...!onlyDownload && { height: 400 },
                        overflow: 'hidden',
                    }}
                >
                    {onlyDownload 
                        ? renderNewComputer()
                        : (
                            <>
                                <Tabs
                                    value={activeTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={handleChangeTab}
                                >
                                    <Tab
                                        label={<Typography>Déclarer un nouveau poste</Typography>}
                                        wrapped
                                    />
                                    <Tab
                                        disabled={_.isEmpty(computersAvailable)}
                                        label={<Typography>Ajouter un poste déjà déclaré</Typography>}
                                        wrapped
                                    />
                                </Tabs>
                                <Container style={{ padding: '2em 0 2em 0' }} maxWidth={false}>
                                    {activeTab === 0 ? renderNewComputer() : renderComputerAlreadyExist()}
                                </Container>
                            </>
                        )
                    }
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        color="primary"
                        disableRipple
                        startIcon={<Close />}
                    >
                        Fermer
                    </Button>
                    {activeTab > 0 && (
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            color="primary"
                            disableRipple
                            disabled={loading}
                            startIcon={loading ? <CircularProgress size={20} color='white' /> : <Check />}
                        >
                            Valider
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

DialogNewComputer.propTypes = {
    onSubmit: PropTypes.func,
    attachedComputers: PropTypes.array,
    onlyDownload: PropTypes.bool,
    onClose: PropTypes.func,
};

DialogNewComputer.defaultProps ={
    onlyDownload: false,
}

export default DialogNewComputer;
