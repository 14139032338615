import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
    Grid,
    Typography,
    TextField,
    Button,
    Hidden,
    CircularProgress,
} from '@material-ui/core';

import { DeleteOutline, Check } from '@material-ui/icons';
import LogContext from '../Context/LogContext';
import ContentHeader from './ContentHeader';
import DialogModifyMail from '../Dialog/DialogModifyMail';
import { modifyUser, deleteCustomerUser } from '../../api';
import DialogConfirm from '../Dialog/DialogConfirm';

const Account = (props) => {
    const { collectivite } = props;
    const {
        name: nomCollectivite,
    } = collectivite || {};

    const {
        refreshActiveUser,
        activeUser,
        token,
        isAuthenticationValid,
        disconnect,
    } = React.useContext(LogContext);

    const {
        first_name: prenomProps,
        last_name: nomProps,
        id_user: identifiantUser,
        email,
        role,
    } = activeUser || {};

    const [prenom, setPrenom] = React.useState(prenomProps || '');
    const [nom, setNom] = React.useState(nomProps || '');
    const [loading, setLoading] = React.useState();
    const [openConfirm, setOpenConfirm] = React.useState(false);

    React.useEffect(() => setPrenom(prenomProps), [prenomProps]);
    React.useEffect(() => setNom(nomProps), [nomProps]);

    const handleChange = ({ target }, setter) => {
        const { value } = target;

        setter(value);
    };

    const handleSave = () => {
        setLoading('save');
        const body = {
            first_name: prenom,
            last_name: nom,
            role,
        };

        fetch(modifyUser(identifiantUser), {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then(refreshActiveUser);
                }
            }
            setLoading(null);
        });
    };

    const handlePasswordClick = () => {
        window.open('https://password.jvsonline.fr/password/forgot?service=https%3A//emu-hc.jvsonline.fr/');
    };

    const handleDeleteClick = () => {
        setLoading('delete');
        fetch(deleteCustomerUser(identifiantUser), {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    setOpenConfirm(false);
                    disconnect(true);
                }
            }
            setLoading(null);
        });
    };

    const renderFirstNameRow = () => (
        <>
            <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography variant='body2'>
                    <b>Prénom</b>
                </Typography>
            </Grid>
            <Grid item lg={5} md={8} sm={9} xs={8}>
                <TextField
                    size='small'
                    fullWidth
                    variant="outlined"
                    value={prenom}
                    onChange={(e) => handleChange(e, setPrenom)}
                />
            </Grid>
            <Hidden only={['xs', 'sm']}>
                <Grid item lg={6} md={2} />
            </Hidden>
        </>
    );

    const renderLastNameRow = () => (
        <>
            <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography variant='body2'>Nom</Typography>
            </Grid>
            <Grid item lg={5} md={8} sm={9} xs={8}>
                <TextField
                    size='small'
                    fullWidth
                    variant="outlined"
                    value={nom}
                    onChange={(e) => handleChange(e, setNom)}
                />
            </Grid>
            <Hidden only={['xs', 'sm']}>
                <Grid item lg={6} md={2} />
            </Hidden>
        </>
    );

    const renderMailRow = () => (
        <>
            <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography variant='body2'>Email</Typography>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={7}>
                <Typography variant='subtitle1'>
                    {email}
                </Typography>
            </Grid>
            <Grid item lg={1} md={2} sm={3} xs={3} justify='flex-end' container>
                <DialogModifyMail />
            </Grid>
            <Hidden only={['xs', 'sm']}>
                <Grid item lg={6} md={2} />
            </Hidden>
        </>
    );

    const renderPasswordRow = () => (
        <>
            <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography variant='body2'>Mot de passe</Typography>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={7}>
                <Typography>**************</Typography>
            </Grid>
            <Grid item lg={1} md={2} sm={3} xs={3} justify='flex-end' container>
                <Button
                    disableRipple
                    onClick={handlePasswordClick}
                    color='primary'
                >
                    Modifier
                </Button>
            </Grid>
            <Hidden only={['xs', 'sm']}>
                <Grid item lg={6} md={2} />
            </Hidden>
        </>
    );

    const renderRoleRow = () => (
        <>
            <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography variant='body2'>Rôle</Typography>
            </Grid>
            <Grid item lg={4} md={7} sm={9} xs={8}>
                <Typography>{role ? 'Administrateur' : 'Utilisateur'}</Typography>
            </Grid>
        </>
    );

    return (
        <Grid container spacing={8}>
            <Grid item xs={12}>
                <ContentHeader libelle='PARAMETRES DE MON COMPTE UTILISATEUR' />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} style={{ paddingTop: '2rem' }}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>
                                    Votre compte est rattaché à :
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    {nomCollectivite}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item xs={12}>
                                <Typography variant='h6'>
                                    Informations de votre compte
                                </Typography>
                            </Grid>
                            {renderFirstNameRow()}
                            {renderLastNameRow()}
                            {renderMailRow()}
                            {renderPasswordRow()}
                            {renderRoleRow()}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container xs={12}>
                            <Grid item xl={2} lg={3} md={5} sm={5} xs={6}>
                                { !role && (
                                    <Button
                                        disableRipple
                                        onClick={() => setOpenConfirm(true)}
                                        variant="outlined"
                                        color='secondary'
                                        disabled={!_.isNil(loading)}
                                        startIcon={loading === 'delete'
                                            ? <CircularProgress size={20} color='white' />
                                            : <DeleteOutline />}
                                    >
                                        Supprimer mon compte
                                    </Button>
                                )}
                                {openConfirm && (
                                    <DialogConfirm
                                        text='Êtes-vous sûr de vouloir supprimer ce compte ?'
                                        onConfirm={handleDeleteClick}
                                        onCancel={() => setOpenConfirm(false)}
                                        loading={loading === 'delete'}
                                    />
                                )}
                            </Grid>
                            <Hidden only={[
                                'xs',
                                'sm',
                                'md',
                            ]}
                            >
                                <Grid item xl={2} lg={1} />
                            </Hidden>
                            <Grid item xl={3} lg={4} md={7} sm={7} xs={6}>
                                <Button
                                    disableRipple
                                    onClick={handleSave}
                                    variant="contained"
                                    color='primary'
                                    disabled={!_.isNil(loading)}
                                    startIcon={loading === 'save'
                                        ? <CircularProgress size={20} color='white' />
                                        : <Check />}
                                >
                                    Enregistrer les modifications
                                </Button>
                            </Grid>
                            <Hidden only={[
                                'xs',
                                'sm',
                                'md',
                            ]}
                            >
                                <Grid item xs={4} />
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

Account.propTypes = {
    collectivite: PropTypes.object,
};

export default Account;
