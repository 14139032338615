import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    Typography,
    DialogTitle,
    Grid,
    TextField,
    Snackbar
} from '@material-ui/core';
import { 
    Check,
    Close,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import { LogContext } from '../Context';
import { updateCustomer } from '../../api';

const DialogAddCustomer = (props) => {
    const {
        onClose,
        onSubmit,
        customer,
    } = props;

    const { name: nameProps, id_node: identifiantRgProps }  = customer;
    const [name, setName] = React.useState(nameProps);
    const [identifiantRg, setIdentifiantRg] = React.useState(identifiantRgProps);
    const [success, setSuccess] = React.useState();
    const [loading, setLoading] = React.useState(false);

    const { token, isAuthenticationValid } = React.useContext(LogContext);

    const { id_customer: identifiant } = customer || {};

    const handleChangeName = ({ target }) => {
        const { value } = target || {};
        
        setName(value);
    
    }
    const handleChangeIdentifiantRg = ({ target }) => {
        const { value } = target || {};
        
        setIdentifiantRg(value);
    }

    const handleClose = () => {
        if(onClose) { 
            onClose();
        }
    }

    const handleCloseAlert = () => setSuccess(null);

    const handleSubmit = () => {
        setLoading(true);

        fetch(updateCustomer(identifiant), {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                id_node: identifiantRg,
                name,
            }),
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((data) => {
                        setSuccess(true);
                        setLoading(false);
                        if(onSubmit){
                            onSubmit();
                        }
                    })
                }
            }
        }).catch(() => {
            setLoading(false);
            setSuccess(false)
        });
    }

    return (
        <>
            <Dialog open maxWidth='md' fullWidth>
                <DialogTitle>
                    Modifier un client
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3} style={{ alignItems: 'center' }}>
                        <Grid item xs={4}>
                            <Typography variant='body2'>Nom</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField 
                                key='name'
                                name='name'
                                size='small'
                                fullWidth
                                variant="outlined"
                                value={name}
                                onChange={handleChangeName}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='body2'>Identifiant RG System</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField 
                                key='id_node'
                                name='id_node'
                                size='small'
                                fullWidth
                                variant="outlined"
                                value={identifiantRg}
                                onChange={handleChangeIdentifiantRg}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        disableRipple
                        variant="outlined"
                        color="primary"
                        startIcon={<Close />}
                        onClick={handleClose}
                    >
                        Annuler
                    </Button>
                    <Button
                        disableRipple
                        variant="contained"
                        onClick={handleSubmit}
                        color="primary"
                        startIcon={loading
                            ? <CircularProgress size={20} color='white' />
                            : <Check />
                        }
                    >
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={!_.isNil(success)}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleCloseAlert}
            >
                <Alert
                    severity={success ? 'success': 'error'}
                    variant="filled"
                    onClose={handleCloseAlert}
                >
                    {success ? 'Le client a bien été modifié' : 'Une erreur est survenue'}
                </Alert>
            </Snackbar>
        </>
    );
};

DialogAddCustomer.propTypes = {
    text: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
}

export default DialogAddCustomer;
