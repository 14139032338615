import React from 'react';

import {
    Button,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    Grid,
} from '@material-ui/core';
import { ExpandMore, PlayArrow } from '@material-ui/icons';

const UpdateRdpLinks = (props) => {
    const { onRun } = props;

    const handleClick = () => {
        if (onRun) {
            onRun()
        }
    }

    return (
        <ExpansionPanel {...props}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant='h6'>
                    Renseigner tous les liens d'accès des RDP en base de donnée
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid
                    container
                    direction='column' 
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Typography>
                            Cette procédure renseignera tous les liens d&apos;accès des RDP
                            pour tous les postes de tous les clients.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            <i>
                                Cette procédure ne met pas à jour les liens d'accès
                                déjà existant.
                            </i>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            disableRipple
                            onClick={handleClick}
                            variant="contained"
                            color='primary'
                            startIcon={<PlayArrow />}
                        >
                            Lancer la procédure
                        </Button>
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default UpdateRdpLinks;
