import _ from 'lodash';
import React from 'react';

import {
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    Link,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import modalLinkRg from '../../../assets/images/modalLinkRg.png';
import rdpRg from '../../../assets/images/rdpRg.png';
import selectComputer from '../../../assets/images/selectComputer.png';
import connexionRdp from '../../../assets/images/connexionRdp.png';
import connexionCertificat from '../../../assets/images/connexionCertificat.png';

const TakeControlComputer = (props) => {
    const [illustrationVisible, setIllustrationVisible] = React.useState([]);

    const handleIllustrationVisible = (index) => {
        if (_.includes(illustrationVisible, index)) {
            const tmp = _.filter(illustrationVisible, index);
            setIllustrationVisible(tmp);
        } else {
            const tmp = _.union(illustrationVisible, [index]);
            setIllustrationVisible(tmp);
        }
    };

    return (
        <ExpansionPanel {...props}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant='h6'>... Prendre le contrôle de mon poste au bureau</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <ol>
                    <li>
                        <Typography>Aller dans l&apos;onglet Mes postes</Typography>
                    </li>
                    <li>
                        <Typography>
                            Sélectionner le poste dont vous souhaitez prendre le contrôle
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            Cliquer sur le lien &quot;Récupérer le lien d&apos;accès « Client » associé&quot;
                            (
                            <Link style={{ cursor: 'pointer' }} onClick={() => handleIllustrationVisible(1)}>
                                {_.includes(illustrationVisible, 1)
                                    ? "Masquer l'illustration"
                                    : "Afficher l'illustration"}
                            </Link>
                            )
                        </Typography>
                        {_.includes(illustrationVisible, 1) && <img src={selectComputer} alt='select-computer' />}
                    </li>
                    <li>
                        <Typography>
                            Cliquer sur le lien dans la fenêtre qui s&apos;est ouverte
                            (
                            <Link style={{ cursor: 'pointer' }} onClick={() => handleIllustrationVisible(2)}>
                                {_.includes(illustrationVisible, 2)
                                    ? "Masquer l'illustration"
                                    : "Afficher l'illustration"}
                            </Link>
                            )
                        </Typography>
                        {_.includes(illustrationVisible, 2) && <img src={modalLinkRg} alt='modalLinkRg' />}
                    </li>
                    <li>
                        <Typography>
                            Un nouvel onglet s&apos;ouvre dans le navigateur.
                            Cliquer sur le bouton &quot;Télécharger l&apos;accès&quot;
                            (
                            <Link style={{ cursor: 'pointer' }} onClick={() => handleIllustrationVisible(3)}>
                                {_.includes(illustrationVisible, 3)
                                    ? "Masquer l'illustration"
                                    : "Afficher l'illustration"}
                            </Link>
                            )
                        </Typography>
                        <Typography variant='body2'>
                            <i>
                                Une fois le téléchargement effectué, vous disposerez de quelques minutes
                                pour initialiser la connexion.
                                Après ce délai, un nouveau téléchargement sera requis
                            </i>
                        </Typography>
                        {_.includes(illustrationVisible, 3) && <img src={rdpRg} alt='rdp-rg' />}
                    </li>
                    <li>
                        <Typography>
                            Ouvrir le fichier &quot;.rdp&quot; téléchargé pour initialiser la connexion
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            Cliquer sur connexion
                            (
                            <Link style={{ cursor: 'pointer' }} onClick={() => handleIllustrationVisible(4)}>
                                {_.includes(illustrationVisible, 4)
                                    ? "Masquer l'illustration"
                                    : "Afficher l'illustration"}
                            </Link>
                            )
                        </Typography>
                        {_.includes(illustrationVisible, 4) && <img src={connexionRdp} alt='connexion-rdp' />}
                    </li>
                    <li>
                        <Typography>
                            Saisisser vos identifiants et connectez-vous
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            Il est possible que l&apos;identité de votre poste ne soit pas reconnue.
                        </Typography>
                        <Typography>
                            Une nouvelle fenêtre propose de se connecter malgré les erreurs de certificat
                        </Typography>
                        <Typography>
                            Cliquer sur le bouton &quot;Oui&quot; pour ignorer ce message
                        </Typography>
                        <Typography>
                            Pour ne plus afficher cet avertissement cocher la case &quot;Ne pas me redemander pour les connexions à cet ordinateur&quot;.
                            (
                            <Link style={{ cursor: 'pointer' }} onClick={() => handleIllustrationVisible(5)}>
                                {_.includes(illustrationVisible, 5)
                                    ? "Masquer l'illustration"
                                    : "Afficher l'illustration"}
                            </Link>
                            )
                        </Typography>
                        {_.includes(illustrationVisible, 5) && <img src={connexionCertificat} alt='connexion-certificat' />}
                    </li>
                </ol>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default TakeControlComputer;
