import React from 'react';

import { Grid, Box } from '@material-ui/core';

import { DashboardContext } from '../Context';

import ContentHeader from '../Content/ContentHeader';
import DashboardCustomer from './DashboardCustomer';
import DashboardUser from './DashboardUser';
import DashboardComputer from './DashboardComputer';

const Dashboard = () => {
    const [customers, setCustomers] = React.useState([]);
    const [users, setUsers] = React.useState(null);
    const [usersLoading, setUsersLoading] = React.useState(false);
    const [activeCustomer, setActiveCustomer] = React.useState();
    const [reload, setReload] = React.useState(false);

    const handleUserLoading = React.useCallback((load) => {
        setUsersLoading(load);
    }, []);

    return (
        <DashboardContext.Provider value={
            {
                reload,
                customers,
                users,
                activeCustomer,
                setReload,
                setCustomers,
                setUsers,
                setActiveCustomer,
            }
        }
        >
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <ContentHeader libelle='GESTION DE MONPOSTEADISTANCE.FR' />
                </Grid>
                <Grid item xs={12}>
                    <Box style={{ display: 'flex', height: 700 }}>
                        <Box style={{
                            display: 'flex',
                            flex: 3,
                            padding: '1em',
                            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
                        }}
                        >
                            <DashboardCustomer />
                        </Box>
                        <Box style={{
                            display: 'flex',
                            flex: 2,
                            flexDirection: 'column',
                            padding: '1em',
                        }}
                        >
                            <Box style={{
                                display: 'flex',
                                padding: '1em',
                                minHeight: 'calc(50% - 2.5em)',
                                marginBottom: '0.5em',
                                boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
                            }}
                            >
                                <DashboardComputer loading={usersLoading} />
                            </Box>
                            <Box style={{
                                display: 'flex',
                                minHeight: 'calc(50% - 2.5em)',
                                padding: '1em',
                                marginTop: '0.5em',
                                boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
                            }}
                            >
                                <DashboardUser onLoad={handleUserLoading} />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </DashboardContext.Provider>
    );
};

export default Dashboard;
