import _ from 'lodash';
import React from 'react';

import {
    Typography,
    Link,
} from '@material-ui/core';

import addComputer from '../../../assets/images/addComputer.png';
import addComputerModal from '../../../assets/images/addComputerModal.png';

const AddNewComputer = (props) => {
    const [illustrationVisible, setIllustrationVisible] = React.useState([]);

    const handleIllustrationVisible = (index) => {
        if (_.includes(illustrationVisible, index)) {
            const tmp = _.filter(illustrationVisible, index);
            setIllustrationVisible(tmp);
        } else {
            const tmp = _.union(illustrationVisible, [index]);
            setIllustrationVisible(tmp);
        }
    };

    return (
        <ol>
            <li>
                <Typography>Aller dans l&apos;onglet Mes postes</Typography>
            </li>
            <li>
                <Typography>
                    Cliquer sur le bouton &quot;Ajouter un poste&quot;
                    (
                    <Link style={{ cursor: 'pointer' }} onClick={() => handleIllustrationVisible(1)}>
                        {_.includes(illustrationVisible, 1)
                            ? "Masquer l'illustration"
                            : "Afficher l'illustration"}
                    </Link>
                    )
                </Typography>
                {_.includes(illustrationVisible, 1) && <img src={addComputer} alt='add-computer' />}
            </li>
            <li>
                <Typography>
                    Suivre les instructions de l'onglet "Déclarer un nouveau poste"
                    (
                    <Link style={{ cursor: 'pointer' }} onClick={() => handleIllustrationVisible(2)}>
                        {_.includes(illustrationVisible, 2)
                            ? "Masquer l'illustration"
                            : "Afficher l'illustration"}
                    </Link>
                    )
                </Typography>
                {_.includes(illustrationVisible, 2) && <img src={addComputerModal} alt='add-first-computer-modal' />}
            </li>
        </ol>
    );
};

export default AddNewComputer;
