const urlBase = 'https://api.securitenumerique.cloud';
// const urlBase = 'http://localhost:8080';

export const authentification = () => `${urlBase}/auth`;
export const token = (id, login, host) => `${urlBase}/auth/token?identifiant=${id}&login=${login}&host=${host}`;

export const userInformations = (identifiantUser) => (
    identifiantUser
        ? `${urlBase}/cybersecurite/users/${identifiantUser}/informations`
        : `${urlBase}/cybersecurite/users/informations`
);

export const userComputers = (identifiantUser) => (
    identifiantUser
        ? `${urlBase}/cybersecurite/users/${identifiantUser}/computers`
        : `${urlBase}/cybersecurite/users/computers`
);
export const inviteUser = () => `${urlBase}/cybersecurite/users`;

export const inviteUserByCustomer = (identifiantCustomer) => `${urlBase}/cybersecurite/customers/${identifiantCustomer}/users`;

export const customerInformations = () => `${urlBase}/cybersecurite/customers/informations`;
export const customerComputers = (identifiantCustomer) => (
    identifiantCustomer
        ? `${urlBase}/cybersecurite/customers/${identifiantCustomer}/computers`
        : `${urlBase}/cybersecurite/customers/computers`
);

export const customerComputersIncrease = () => `${urlBase}/cybersecurite/customers/computers/increase`;
export const customerUsers = (identifiantCustomer) => (
    identifiantCustomer
        ? `${urlBase}/cybersecurite/customers/${identifiantCustomer}/users`
        : `${urlBase}/cybersecurite/customers/users`
);

export const computerUsers = (identifiantRg) => (
    identifiantRg
        ? `${urlBase}/cybersecurite/computers/users?id_computer_rg=${identifiantRg}`
        : `${urlBase}/cybersecurite/computers/users`
);

export const createComputer = () => `${urlBase}/cybersecurite/computers`;

export const createUserComputer = () => `${urlBase}/cybersecurite/userComputer`;

export const deleteUserComputer = (identifiantRg, idUser) => (
    idUser
        ? `${urlBase}/cybersecurite/userComputer?id_computer=${identifiantRg}&id_user=${idUser}`
        : `${urlBase}/cybersecurite/userComputer?id_computer=${identifiantRg}`
);
export const deleteComputer = (identifiantComputer) => `${urlBase}/cybersecurite/computers/${identifiantComputer}`;

export const deleteCustomerUser = (idUser) => `${urlBase}/cybersecurite/customersUsers?id_user=${idUser}`;

export const getRdp = (identifiantRg) => `${urlBase}/cybersecurite/userComputers/rdp?id_computer_rg=${identifiantRg}`;

export const counter = (identifiantCustomer) => (
    identifiantCustomer
        ? `${urlBase}/cim/customers/${identifiantCustomer}/counter`
        : `${urlBase}/cim/customers/counter`
);

export const cimUsers = (identifiantCustomer) => (
    identifiantCustomer
        ? `${urlBase}/cim/customers/${identifiantCustomer}/users`
        : `${urlBase}/cim/customers/users`
);

export const modifyUser = (idUser) => `${urlBase}/cybersecurite/users/${idUser}`;

export const customers = (sort, page = 1) => `${urlBase}/cybersecurite/customers/?page=${page}&sort=${sort}`;
export const searchCustomers = (q, sort, page = 1,) => `${urlBase}/cybersecurite/customers/search/?q=${q}&page=${page}&sort=${sort}`;

/* DASHBOARD */
export const customerUserInformations = (identifiantCustomer, identifiantUser) => (
    `${urlBase}/cybersecurite/customers/${identifiantCustomer}/users/${identifiantUser}/informations`
);

export const customerUserComputers = (identifiantCustomer, identifiantUser) => (
    `${urlBase}/cybersecurite/customers/${identifiantCustomer}/users/${identifiantUser}/computers`
);

export const createCustomerUserComputer = (identifiantCustomer) => `${urlBase}/cybersecurite/customers/${identifiantCustomer}/userComputer`;

export const createCustomerComputer = (identifiantCustomer) => `${urlBase}/cybersecurite/customers/${identifiantCustomer}/computers`;


export const deleteCustomerUserComputer = (identifiantCustomer, identifiantUser, identifiantComputer) => (
    `${urlBase}/cybersecurite/customers/${identifiantCustomer}/userComputer?id_user=${identifiantUser}&id_computer=${identifiantComputer}`
);

export const deleteCustomerComputer = (identifiantCustomer, identifiantComputer) => (
    `${urlBase}/cybersecurite/customers/${identifiantCustomer}/computers/${identifiantComputer}`
);

export const deleteAgentRg = (identifiantRg) => (
    `${urlBase}/rg/agents/${identifiantRg}`
);

export const modifyCustomerUser = (identifiantCustomer, identifiantUser) => `${urlBase}/cybersecurite/customers/${identifiantCustomer}/users/${identifiantUser}`;

export const deleteCustomerUserDashboard = (identifiantCustomer, identifiantUser) => `${urlBase}/cybersecurite/customers/${identifiantCustomer}/users/${identifiantUser}`;

export const incrementCustomerCompteur = (identifiantCustomer) => `${urlBase}/cybersecurite/customers/${identifiantCustomer}/increment`

export const decrementCustomerCompteur = (identifiantCustomer) => `${urlBase}/cybersecurite/customers/${identifiantCustomer}/decrement`

export const cimCustomers = () => `${urlBase}/cim/customers`

export const createCustomer = () => `${urlBase}/cybersecurite/customers`

export const updateCustomer = (identifiantCustomer) => `${urlBase}/cybersecurite/customers/${identifiantCustomer}`

export const deleteCustomer = (identifiantCustomer) => `${urlBase}/cybersecurite/customers/${identifiantCustomer}`
