import _ from 'lodash';
import React from 'react';

import {
    Grid,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    CircularProgress,
} from '@material-ui/core';

import LogContext from '../../Context/LogContext';
import DialogManageUser from '../../Dialog/DialogManageUser';
import DialogInviteNewUser from '../../Dialog/DialogInviteNewUser';

import {
    customerUsers as getCustomerUsers,
    deleteCustomerUser,
} from '../../../api';
import DialogConfirm from '../../Dialog/DialogConfirm';

const UtilisateursTab = () => {
    const { token, isAuthenticationValid, activeUser } = React.useContext(LogContext);

    const { role: roleActiveUser, id_user: identifiantActiveUser } = activeUser || {};

    const [selectedRow, setSelectedRow] = React.useState(null);
    const [users, setUsers] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [openConfirm, setOpenConfirm] = React.useState();
    const [openModal, setOpenModal] = React.useState();

    const getUsers = React.useCallback(() => {
        setLoading('user');

        fetch(getCustomerUsers(), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((data) => {
                        setUsers(data);
                    });
                }
            }
            setLoading(null);
        });
    }, [isAuthenticationValid, token]);

    React.useEffect(() => {
        getUsers();
    }, [getUsers]);

    const handleClickRow = (id) => {
        setSelectedRow(id);
    };

    const handleClickDelete = (id) => {
        setLoading('delete');

        fetch(deleteCustomerUser(id), {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    setUsers((prevState) => _.filter(
                        prevState,
                        (u) => u.id_user !== id,
                    ));
                }
            }
            setLoading(null);
            setOpenConfirm();
        });
    };

    const handleSave = () => {
        getUsers();
    };

    const handleSend = () => getUsers();

    const handleDeleteUser = (identifiantUser) => () => handleClickDelete(identifiantUser);
    
    const handleOpenModal = (user) => setOpenModal(user);

    const handleCloseModal = () => setOpenModal(null);

    if (loading === 'user') {
        return (
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <CircularProgress size={24} />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography variant='body2'>Utilisateurs</Typography></TableCell>
                                <TableCell><Typography variant='body2'>Rôles</Typography></TableCell>
                                <TableCell><Typography variant='body2'>Rattachements</Typography></TableCell>
                                <TableCell />
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(users, (user) => {
                                const {
                                    first_name: firstName,
                                    last_name: lastName,
                                    customer_users: customerUsers,
                                    id_user: identifiantUser,
                                    computers,
                                } = user;

                                const { role } = customerUsers[0] || {};

                                const nombrePostes = _.size(computers);
                                const identite = `${firstName} ${_.toUpper(lastName)}`;

                                return (
                                    <TableRow
                                        {...!_.isNil(nombrePostes) && nombrePostes !== 0 ? { } : { className: 'unattached' }}
                                        key={`row${identifiantUser}`}
                                        onClick={() => handleClickRow(identifiantUser)}
                                        selected={selectedRow === identifiantUser}
                                    >
                                        <TableCell>
                                            <Typography>{identite}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{role ? 'Administrateur' : 'Utilisateur'}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                {nombrePostes}
                                                {' '}
                                                {nombrePostes > 1 ? 'postes' : 'poste'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                disableRipple
                                                onClick={() => handleOpenModal(user)}
                                                color='primary'
                                                style={{ visibility: !_.isNil(selectedRow) && selectedRow === identifiantUser ? 'visible' : 'hidden' }}
                                            >
                                                Gérer
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            { identifiantActiveUser !== identifiantUser && roleActiveUser && (
                                                <Button
                                                    disableRipple
                                                    color='secondary'
                                                    style={{ visibility: !_.isNil(selectedRow) && selectedRow === identifiantUser ? 'visible' : 'hidden' }}
                                                    onClick={() => setOpenConfirm(identifiantUser)}
                                                >
                                                    Supprimer cet utilisateur
                                                </Button>
                                            )}
                                        </TableCell>

                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    {!_.isNil(openConfirm) && (
                        <DialogConfirm
                            text='Êtes-vous sûr de vouloir supprimer cet utilisateur ?'
                            onConfirm={handleDeleteUser(openConfirm)}
                            onCancel={() => setOpenConfirm(null)}
                            loading={loading === 'delete'}
                        />
                    )}
                      {!_.isNil(openModal) && (
                        <DialogManageUser
                            libelleUser={`${_.toUpper(openModal.last_name)} ${openModal.first_name}`}
                            identifiantUser={openModal.id_user}
                            buttonVisible={!_.isNil(selectedRow) && selectedRow === openModal.id_user}
                            onSave={handleSave}
                            onUserDelete={() => handleClickDelete(openModal.id_user)}
                            onClose={handleCloseModal}
                        />
                    )}
                </Grid>
                <Grid item>
                    <DialogInviteNewUser 
                        onSend={handleSend} 
                        users={users}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UtilisateursTab;
