import _ from 'lodash';
import React from 'react';

import {
    Grid,
} from '@material-ui/core';

import ContentHeader from '../ContentHeader';

import TakeControlComputer from './TakeControlComputer';
import MultiScreen from './MultiScreen';
import SwapComputer from './SwapComputer';
import AddComputer from './AddComputer';
import ControlMac from './ControlMac';

const FAQ = () => {
    const [expandedPanels, setExpandedPanels] = React.useState([]);

    const handleChange = (index) => (e, isExpanded) => {
        setExpandedPanels(isExpanded
            ? _.uniq([...expandedPanels, index])
            : _.filter(expandedPanels, pan => pan !== index)
        );
    }

    const handleAddComputerClick = () => {
        setExpandedPanels(_.uniq([
            ..._.filter(expandedPanels, pan => pan !== 4),
            0
        ]));
    }

    return (
        <Grid container spacing={8}>
            <Grid item xs={12}>
                <ContentHeader libelle='COMMENT FAIRE POUR ...' />
            </Grid>
            <Grid item xs={12}>
                <AddComputer
                    expanded={_.includes(expandedPanels, 0)}
                    onChange={handleChange(0)}
                />
                <TakeControlComputer
                    expanded={_.includes(expandedPanels, 1)}
                    onChange={handleChange(1)}
                />
                <ControlMac
                    expanded={_.includes(expandedPanels, 2)}
                    onChange={handleChange(2)}
                />
                <MultiScreen
                    expanded={_.includes(expandedPanels, 3)}
                    onChange={handleChange(3)}
                />
                <SwapComputer
                    expanded={_.includes(expandedPanels, 4)}
                    onChange={handleChange(4)}
                    onAddComputerClick={handleAddComputerClick}
                    />
            </Grid>
        </Grid>
    )
};

export default FAQ;
