import React from 'react';

import PropTypes from 'prop-types';

import { Container, CircularProgress, Box } from '@material-ui/core';

import Computers from '../Content/Computers';
import Administration from '../Content/Administration/Administration';
import Account from '../Content/Account';
import Activation from '../Content/Activation/Activation';
import FAQ from '../Content/FAQ/FAQ';
import Dashboard from '../Dashboard/Dashboard';
import Process from '../Dashboard/Process/Process';

const ApplicationContent = (props) => {
    const {
        activeMenu,
        firstConnexion,
        onActivationEnd,
        activeCollectivite,
        loading,
        admin,
    } = props;

    const handleActivationEnd = () => {
        if (onActivationEnd) onActivationEnd();
    };

    const renderContent = () => {
        if (admin) {
            switch (activeMenu) {
                case 0:
                    return <Dashboard />;
                case 1:
                    return <Process />;
                default:
                    return null;
            }
        } else {
            switch (activeMenu) {
                case 0:
                    return <Computers />;
                case 1:
                    return <Account collectivite={activeCollectivite} />;
                case 2:
                    return <FAQ />;
                case 3:
                    return <Administration collectivite={activeCollectivite} />;
                default:
                    return null;
            }
        }
    };

    const renderActivation = () => (<Activation onEnd={handleActivationEnd} />);

    if (loading) {
        return (
            <Box
                style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress size={24} />
            </Box>
        );
    }

    return (
        <Container style={{ padding: '2rem' }} maxWidth={false}>
            {
                firstConnexion
                    ? renderActivation()
                    : renderContent()
            }
        </Container>
    );
};

ApplicationContent.propTypes = {
    activeMenu: PropTypes.number,
    firstConnexion: PropTypes.bool,
    onActivationEnd: PropTypes.func,
    activeCollectivite: PropTypes.object,
    admin: PropTypes.bool,
};

export default ApplicationContent;
