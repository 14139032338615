import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    Typography,
    DialogTitle,
    Grid,
    TextField,
    Snackbar
} from '@material-ui/core';
import { 
    Check,
    Close,
} from '@material-ui/icons';
import { Autocomplete, Alert } from '@material-ui/lab';

import { LogContext } from '../Context';
import { cimCustomers, createCustomer } from '../../api';

const DialogAddCustomer = (props) => {
    const {
        onClose,
        onSubmit
    } = props;
    
    const [customers, setCustomers] = React.useState([]);
    const [customerSelected, setCustomerSelected] = React.useState();
    const [nodeRg, setNodeRg] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState();

    const { token, isAuthenticationValid } = React.useContext(LogContext);

    React.useEffect(() => {
        setLoading(true);

        fetch(cimCustomers(), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (isAuthenticationValid(response)) {
                if (response.status && response.status === 200) {
                    response.json().then((data) => {
                        setLoading(false);
                        setCustomers(data);
                    })
                }
            }
        });
    }, [isAuthenticationValid, token])

    const handleChange = ({ target }) => {
        const { value } = target;

        setNodeRg(value);
    }

    const handleClose = () => {
        if(onClose) { 
            onClose();
        }
    }

    const handleSubmit = () => {
        setLoading(true);
        
        if(!_.isNil(customerSelected) && !_.isEmpty(nodeRg)){
            fetch(createCustomer(), {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    id_cim: customerSelected?.id,
                    id_node: nodeRg,
                    name: customerSelected?.name,
                    external_id: !_.isNil(customerSelected.external_id) 
                        ?   _.padStart(customerSelected.external_id, 5, '0')
                        : null,
                }),
            }).then((response) => {
                if (isAuthenticationValid(response)) {
                    if (response.status && response.status === 200) {
                        response.json().then((data) => {
                            setLoading(false);

                            if(onSubmit){
                                onSubmit();
                            }
                        })
                    }
                }
            });
        } else {
            setError('Tous les champs sont obligatoires.')
            setLoading(false);
        }

    }

    const handleChangeAutocomplete = (e, customer) => setCustomerSelected(customer);

    const handleCloseError = () => setError();

    return (
        <>
            <Dialog open maxWidth='md' fullWidth>
                <DialogTitle>
                    Ajouter un client
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3} style={{ alignItems: 'center' }}>
                        {
                            loading 
                                ? 
                                    (
                                        <Grid item style={{
                                            display: 'flex',
                                            flex: 1,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: 130
                                        }}>
                                            <CircularProgress size={24} /> 
                                        </Grid>
                                    )
                                : 
                                    (
                                        <>
                                            <Grid item xs={4}>
                                                <Typography variant='body2'>Nom de la collectivité</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    options={customers}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => <TextField {...params} size='small' fullWidth variant="outlined" />}
                                                    onChange={handleChangeAutocomplete}
                                                    ChipProps={{color: 'primary'}}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant='body2'>Identifiant node RG System</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField 
                                                    key='idNodeRg'
                                                    name='idNodeRg'
                                                    value={nodeRg}
                                                    onChange={handleChange}
                                                    size='small'
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    />
                                            </Grid>
                                        </>
                                    )
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        disableRipple
                        variant="outlined"
                        color="primary"
                        startIcon={<Close />}
                        onClick={handleClose}
                    >
                        Annuler
                    </Button>
                    <Button
                        disableRipple
                        variant="contained"
                        onClick={handleSubmit}
                        color="primary"
                        startIcon={<Check />}
                    >
                        Ajouter
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={!_.isNil(error)}
                autoHideDuration={6000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleCloseError}
            >
                <Alert
                    severity="error"
                    variant="filled"
                    onClose={handleCloseError}
                >
                    {error}
                </Alert>
            </Snackbar>
        </>
    );
};

DialogAddCustomer.propTypes = {
    text: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
}

export default DialogAddCustomer;
