import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    Typography,
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

import LightTooltip from '../../LightTooltip';

const OrganisationTab = (props) => {
    const { compteur, collectivite } = props;

    const { used, total } = compteur || {};
    const {
        name,
        external_id: identifiantClient,
    } = collectivite || {};

    return (
        <Grid item xs={12} style={{ paddingTop: 14 }}>
            <Grid container spacing={1}>
                <Grid item xl={1} lg={2} md={3} sm={5} xs={9}>
                    <Typography variant='body2' paragraph>
                        Identifiant client
                    </Typography>
                </Grid>
                <Grid item xl={11} lg={10} md={9} sm={7} xs={3}>
                    <Typography>
                        {identifiantClient}
                    </Typography>
                </Grid>
                <Grid item xl={1} lg={2} md={3} sm={5} xs={9}>
                    <Typography variant='body2' paragraph>
                        Nom
                    </Typography>
                </Grid>
                <Grid item xl={11} lg={10} md={9} sm={7} xs={3}>
                    <Typography>
                        {name}
                    </Typography>
                </Grid>
                <Grid item xl={1} lg={2} md={3} sm={5} xs={9}>
                    <Typography variant='body2' paragraph>
                        Consommation
                    </Typography>
                </Grid>
                <Grid item xl={11} lg={10} md={9} sm={7} xs={3}>
                    <Typography>
                        <b>
                            {used}
                            {' '}
                        </b>
                        { used && used > 1 ? "postes" : "poste" }
                        <LightTooltip
                            placement='bottom'
                            title='Le poste qui sera contrôlé à distance par le poste client'
                        >
                            <span style={{ color: '#1976d2' }}>
                                {' '}
                                « hôte »
                                <InfoOutlined style={{ marginBottom: 2, fontSize: '1rem' }} />
                                {' '}
                            </span>
                        </LightTooltip>
                        sur
                        <b>
                            {' '}
                            {total}
                            {' '}
                        </b>
                        { total && total > 1
                            ? "autorisés sont prêts pour la prise de contrôle à distance"
                            : "autorisé est prêt pour la prise de contrôle à distance"
                        }
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

OrganisationTab.propTypes = {
    compteur: PropTypes.object,
    collectivite: PropTypes.object,
};

export default OrganisationTab;
