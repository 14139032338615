import React from 'react';

import PropTypes from 'prop-types';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    Typography
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';

const DialogConfirm = (props) => {
    const {
        text,
        onConfirm,
        onCancel,
        loading
    } = props;

    const handleConfirm = () => {
        if (onConfirm) onConfirm();
    }

    const handleCancel = () => {
        if (onCancel) onCancel();
    }

    return (
        <Dialog open>
            <DialogContent dividers>
                <Typography>{text}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    disableRipple
                    variant="outlined"
                    color="primary"
                    disabled={loading}
                    startIcon={<Close />}
                    onClick={handleCancel}
                >
                    Non
                </Button>
                <Button
                    disableRipple
                    variant="contained"
                    onClick={handleConfirm}
                    color="secondary"
                    disabled={loading}
                    {...loading && { style: {
                        backgroundColor: 'rgb(220, 0, 78)',
                        opacity: '0.6',
                        color: 'white',
                    }}}
                    startIcon={loading
                        ? <CircularProgress size={20} color='white' />
                        : <Check />
                    }
                >
                    Oui
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DialogConfirm.propTypes = {
    text: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
}

export default DialogConfirm;
